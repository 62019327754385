import { Component, OnInit } from '@angular/core';
import{LoginResponseService} from'../loginresponeservice';
import { ApiService } from '../api.service';
import{StudyModelRequest} from'../studymodelrequst';
//import{LoginResponseModel} from'../loginmodelresponse';
import{StudyModelResponse} from'../studymodelrespone';
import {Router} from "@angular/router";
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

   logindisplayName:String;
   loginPicture:String;
   studyModelRequest=new StudyModelRequest();
   studyModelRes=new StudyModelResponse();
   studydata :Number;
   showLabel: any;
  //loginResponseMsg:string;
  constructor(private loginResponseService:LoginResponseService,
    private apiSevice: ApiService,private route:Router){}
  ngOnInit(){
    let logiResoutputdata2 = JSON.parse(localStorage.getItem("datas")|| '{}');
    console.log(logiResoutputdata2);
    console.log(logiResoutputdata2.result.displayName);
    console.log(logiResoutputdata2.result.displayPictureUrl);
    //to dispaly login details names
    this.logindisplayName=logiResoutputdata2.result.displayName;
    this.loginPicture=logiResoutputdata2.result.displayPictureUrl;
     // study response api and  get study details 
    this.studyModelRequest.userId=logiResoutputdata2.result.userId;
    this.studyModelRequest.otherId=logiResoutputdata2.result.otherId;
    this.studyModelRequest.message=logiResoutputdata2.message;
    this.apiSevice.studyDetailMethod( this.studyModelRequest)
    .subscribe(studyModelResdata=>{
      console.log(studyModelResdata);
      //for display the data study details
    this.studyModelRes=studyModelResdata;
    this.studyModelRes.studyName=studyModelResdata.result.studyName;
    this.studyModelRes.trailSites=studyModelResdata.result.trailSites;
    this.studyModelRes.startDate=studyModelResdata.result.startDate;
    this.studyModelRes.endDate=studyModelResdata.result.endDate;
    this.studyModelRes.patientDuration=studyModelResdata.result.patientDuration;
    this.studyModelRes.totalVisits=studyModelResdata.result.totalVisits;
    this.studyModelRes.contactName=studyModelResdata.result.companyName;
    this.studyModelRes.contactMobile=studyModelResdata.result.contactMobile;
    this.studyModelRes.contactEmail=studyModelResdata.result.contactEmail;
    this.studyModelRes.studyId=studyModelResdata.result.studyId;
    this.studyModelRes.protocolNumber=studyModelResdata.result.protocolNumber;
    this.studyModelRes.trailSiteId=studyModelResdata.result.trailSiteId;
    this.studyModelRes.studyStatus=studyModelResdata.result.studyStatus
    console.log("value"+studyModelResdata.result.trailSiteId);
    console.log(this.studyModelRes.protocolNumber);
    this.studydata=this.studyModelRes.studyId;
    this.loginResponseService.myMethod(this.studydata);
    localStorage.setItem('studyId', JSON.stringify(this.studydata))
    localStorage.setItem('studydetails', JSON.stringify(this.studyModelRes))
   //condition for status
    if ( this.studyModelRes.studyStatus=='OPEN') {
      this.showLabel = true;
    }else{
      this.showLabel=false;
    }
   })
  
  }
  //roter method
  routermethod(e: any) {
    // console.log(e)
     if (e == 'study') {
       this.route.navigate(['studydocuments']);
     } else if (e == 'patient') {
       this.route.navigate(['participant']);
     } else if (e == 'queries') {
       this.route.navigate(['selectedlist']);
     }
   }
}
