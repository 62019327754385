<!DOCTYPE html>
<html lang="en">

<head>
   <title>Bootstrap Example</title>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
</head>
<body>
  <app-header></app-header>
  <br>
  <div class="container-fluid">
    <div class="row ">
      <div class="col-sm-3 sidenav">
        <div class="flex_row" style="text-align: center;">
          <div class="article onRight flex_row">
            <div class="image mb-2">
              <img class="rounded-circle" alt="100x100" [src]="this.loginPicture" style="object-fit: scale-down;">
            </div>
            <div calss="text-center" class="imagedata">
              <p>{{this.logindisplayName}}</p>
              <a href="#" [routerLink]="['/myprofile']" class="profile">VIEW PROFILE</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-9">
        <div class="row" class="tableheight">
          <table class="table table-dark" id="t01">
            <tbody>
              <tr>
                <td>Study Name</td>
                <td class="line"> {{this.studyModelRes.studyName}}</td>
              </tr>
              <tr>
                <td>Study Start Date</td>
                <td class="line">{{ this.studyModelRes.startDate}}</td>
              </tr>
              <tr>
                <td>Study Approximate End Date</td>
                <td class="line">{{this.studyModelRes.endDate}}</td>
              </tr>
              <tr>
                <td>Protocol Number</td>
                <td class="line">{{this.studyModelRes.protocolNumber}}</td>
              </tr>
              <tr>
                <td>Sponsor</td>
                <td class="line">{{ this.studyModelRes.contactName}}</td>
              </tr>
              <tr>
                <td>StudyStatus</td>
                <td class="line">{{this.studyModelRes.studyStatus}}</td>
              </tr>
              <tr>
                <td>No.of Visits</td>
                <td class="line">{{this.studyModelRes.totalVisits}}</td>
              </tr>
              <tr>
                <td>Patient Treatment Duration</td>
                <td class="line">5 Weeks</td>

              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div> 
 
 <div class="container-fluid bg-3 text-center">
    <div class="row" *ngIf="showLabel">
    
        <div class="col-md-4" (click)="routermethod('study')" style="cursor:pointer;">
          <div class="box-example1">
            <a href="#" [routerLink]="['/studydocuments']"  class="rowcolor">STUDY<br>DOCUMENTS</a>

          </div>
        </div>

        <div class="col-md-4" (click)="routermethod('patient')" style="cursor:pointer;">
          <div class="box-example2">
            <a href="#" [routerLink]="['/participant']"  class="rowcolor">PARTICIPANT<br>STATUS</a>

          </div>
        </div>
        <div class="col-md-4" (click)="routermethod('queries')"  style="cursor:pointer;">
          <div class="box-example3">
            <a href="#" [routerLink]="['/selectedlist']"  class="rowcolor">MEDICATION<br> DETAILS</a>

          </div>
        </div>
      </div>
  </div> 
  
  <div class="statusclosed" *ngIf="showLabel==false">
    <p>You Can't access this Study , Please contact your Sponsor</p>
  </div>
  
</body>
</html>