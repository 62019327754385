<!DOCTYPE html>
<html lang="en">

<head>

  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
  <style>
  </style>
</head>

<body>
  <app-header></app-header>
  <br>
  <div class="container-fluid" >
    <div class="row">
        <div class="col-md-6">
          <a href="#" [routerLink]="['/dashboard']"  class="home">Home /</a> 
          <a href="#" [routerLink]="['/participant']"  class="study">Participant Status</a> 
        </div>  
        <div class="col-sm-2">
          <div class='box primary'></div>
          <h6 class="enrolled">SCREENING PENDING</h6>
        </div>
        <div class="col-sm-2">
          <div class='box red'></div>
          <h6 class="disposed" >ENROLLED</h6>
        </div>
        <div class="col-sm-2">
          <div class='box green'></div>
          <h6 class="disposed" >DROPPED</h6>
        </div>
    </div>
  </div>
 <br>
   <div class="container-fluid">
              <div class="row">
                <div class="col-sm-2">
                  <h6 class="participant">Participant List </h6>
                </div>
                <div class="col-sm-8" style="padding: 11px 0px 0px 0px;">
                  <a href="#" [routerLink]="['/newparticipant']" style="color:#0071ad">+ Add New Participant</a>
                </div>
                <div class="col-sm-2 mb-2">
                  <input type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Search Patient..."
                    style="border-radius: 30px;
                    width: 80%;
                    padding: 2px 4px;
                    margin-top: 7px;
                    float: right;
                    text-align: center;
                    border: 1px solid gray;">
                </div>
                <div class="col-md-12" style="margin-top: -8px;">
                  <table class="table table-dark" id="t01">
                    <thead>
             <tr style="background-color: darkgrey;">
                 <th> Participant Id</th>
                 <th>Participant Status</th>
                 <th>Enrolled Date</th>
             </tr>
          </thead>
       <tbody >
          <tr>
             <tr *ngFor="let item of participantRess| filter:searchText " > 
             <td (click)="getdetailsId(item)" [ngStyle]="{'color': (item.status === 'ACTIVE') ? '#303F9F' : (item.status === 'DROP_OUT') ? '#eb2117' : (item.status === 'IN-PROGRESS') ? '#eb8a17' :(item.status === 'OPEN') ? '#eb8a17' :'' }"
             style="cursor: pointer;"  > {{item.patientId}} 
             <button style="float: right; height: 24px;border:1px solid gray ;cursor: pointer;"
             [ngStyle]="{'color': (item.status === 'ACTIVE') ? '#303F9F' : (item.status === 'DROP_OUT') ? '#eb2117' : (item.status === 'IN-PROGRESS') ? '#eb8a17' :(item.status === 'OPEN') ? '#eb8a17' :'' }">VIEW</button>
            </td>
             <td>{{item.status}}</td>
             <td>{{item.enrolledDate}}</td>
          </tr>
       </tbody>
    </table>
    </div>
  </div>
</div>
</body>

</html>