import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AdverseModel } from '../adversemodel';
import { ApiService } from '../api.service';
import { LabReportsModel } from '../labreportsmodel';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-labreports',
  templateUrl: './labreports.component.html',
  styleUrls: ['./labreports.component.css']
})
export class LabreportsComponent implements OnInit {
  labReportsModels:  LabReportsModel;
  patientId: any;
  adverseModel = new AdverseModel();
  labReportsModel:  LabReportsModel[];
  constructor(private modalService: NgbModal,private route: Router, private apiService: ApiService) { }
  open(content: any,   labReportsModels:  LabReportsModel) {
    this.labReportsModels=labReportsModels;
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
  }
  ngOnInit() {
    let patientId = JSON.parse(localStorage.getItem("datass") || '{}');
    this.adverseModel.patientId = patientId
    let logiResoutputdata2 = JSON.parse(localStorage.getItem("datas") || '{}');
    this.adverseModel.userId = logiResoutputdata2.result.userId;
    this.apiService.labreportsmethod(this.adverseModel)
      .subscribe(labreportdata => {
        console.log(labreportdata)
        this.labReportsModel=labreportdata.result;
        console.log(this.labReportsModel)
       
      })
  }
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('datas');
    this.route.navigate(['login']);
  }
}
