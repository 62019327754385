import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ApiService } from '../api.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscriber } from "rxjs";
import { ParticipantScreeningModel } from '../participantscreeningmodel'

@Component({
  selector: 'app-patientscreening',
  templateUrl: './patientscreening.component.html',
  styleUrls: ['./patientscreening.component.css']
})
export class PatientscreeningComponent implements OnInit {

  participantScreeningModel = new ParticipantScreeningModel()
  Form: FormGroup;
  base64textString: any
  base64result: any
  constructor(private apiSevice: ApiService, private route: Router, private formBuilder: FormBuilder,) {
  }

  ngOnInit() {
    let patientId = JSON.parse(localStorage.getItem("datass") || '{}');
    console.log(patientId)
    this.Form = this.formBuilder.group({

      testvalue: [null, Validators.required],
      image: [null, Validators.required]
    })
   
  }
  onChange(evt: any) {
    const file = evt.target.files[0];
    this.convertToBase64(file);

  }
  convertToBase64(file: File) {
    const observable = new Observable((subscriber: Subscriber<any>) => {
      this.readFile(file, subscriber);
    });
    observable.subscribe((d) => {
      this.base64textString = d;
      this.base64result = this.base64textString.split(',')[1];
      console.log(this.base64result);

    });
  }
  readFile(file: File, subscriber: Subscriber<any>) {
    const filereader = new FileReader();
    filereader.readAsDataURL(file);
    filereader.onload = () => {
      subscriber.next(filereader.result);
      subscriber.complete();
    };
    filereader.onerror = (error) => {
      subscriber.error(error);
      subscriber.complete();
    }
  }


  onSubmit() {
  if(this.Form.valid){
    let mobileNumber= JSON.parse(localStorage.getItem("mobileNumber") || '{}');
    this.participantScreeningModel.mobileNumber=mobileNumber;
    let logiResoutputdata2 = JSON.parse(localStorage.getItem("datas") || '{}');
    this.participantScreeningModel.userId = logiResoutputdata2.result.userId;
    let studyId = JSON.parse(localStorage.getItem("studyId") || '{}');
    this.participantScreeningModel.studyId = studyId;
    let patientId = JSON.parse(localStorage.getItem("datass") || '{}');
    this.participantScreeningModel.patientId = patientId;
    this.participantScreeningModel.testName = "Vitamin D";
    this.participantScreeningModel.testValue = this.Form.value.testvalue;
    this.participantScreeningModel.dayNumber = 1;
    this.participantScreeningModel.fileURL = this.base64result;
    this.apiSevice.patientscreening(this.participantScreeningModel)
      .subscribe(patientscreeingdata => {
        console.log(patientscreeingdata)
        this.route.navigate(['participant']);
      });
    }else {
        alert("Please fill all the Mandatory Fields")
      }
  }
 
}