
 import { Component, OnInit } from '@angular/core';
 import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
 import{DropParticipantResponse} from'../droparticipantresponse';
 import { ApiService } from '../api.service';
 import { FormBuilder,FormControl, FormGroup, Validators } from '@angular/forms';
 import {Router} from "@angular/router";
 import{CrfVisitrequest} from'../CRFVisitRequest';
 import{CrfResponse} from'../CrfRespone';
 
 @Component({
   selector: 'app-crf',
   templateUrl: './crf.component.html',
   styleUrls: ['./crf.component.css']
 })
 export class CRFComponent implements OnInit {
   crfVisitreq=new CrfVisitrequest();
   dropParticipantRes=new DropParticipantResponse();
   dropParticipantdata:any;
   dataremarks:any;
   patientId:Number;
   protocolId:Number;
  crfdatalength:Number;
   patientenrolleddate:any;
   date:any; 
   dates:any;
   crfResArray:CrfResponse[] = [];
    Form: FormGroup;
    enrolleddate:any;
    studyName:any;
    showlabel:any;
    showLabel:any;
  // arr:any=[]
   // emps: CrfResponse[]= []; 
   constructor(private apiSevice: ApiService,private formBuilder: FormBuilder,
     private modalService: NgbModal,private route: Router) {}
   ngOnInit() {
     this.Form = this.formBuilder.group({
       remarks: [null, Validators.required],
     });
     let logiResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');

    //   if (logiResoutputdata.result.roleId == '5') {
    //     this.showlabel=true;
    //   }
    //   if (logiResoutputdata.result.roleId == '14') {
    //     this.showLabel=true;
    //  }
     let patientfulldata = JSON.parse(localStorage.getItem("singlepatientfulldata")|| '{}');
console.log(patientfulldata)
this.enrolleddate=patientfulldata.enrolledDate;
     let erolldate = JSON.parse(localStorage.getItem("enrolleddate")|| '{}');
     console.log(encodeURI)
     this.patientId = JSON.parse(localStorage.getItem("datass")|| '{}');

     let studydata = JSON.parse(localStorage.getItem("studydetails")|| '{}');
     this.studyName=studydata.studyName;
     console.log(studydata)
     this.protocolId=studydata.protocolNumber;
     let logiResoutputdata2 = JSON.parse(localStorage.getItem("datas")|| '{}');
     this.crfVisitreq.userId=logiResoutputdata2.result.userId;
     this.crfVisitreq.patientId=this.patientId;
     let studydetailsdata = JSON.parse(localStorage.getItem("studydetails")|| '{}');
     this.crfVisitreq.studyId=studydetailsdata.studyId;
     this.apiSevice.Visitmethod( this.crfVisitreq)
       .subscribe(crfdata=>{
         console.log(crfdata)
         for(let i=0;i<crfdata.result.length;i++){
           console.log(crfdata.result.length);
          var crfRes=new CrfResponse(); 
          this.crfdatalength=crfdata.result.length;
          crfRes.studyId=crfdata.result[i].studyId
          crfRes.visitId=crfdata.result[i].visitId
          localStorage.setItem('Crfdata', JSON.stringify( crfRes.visitId));
          crfRes.patientId=crfdata.result[i].patientId
          crfRes.doctorId=crfdata.result[i].doctorId
          localStorage.setItem('CrfdoctorId', JSON.stringify(crfRes.doctorId));
          this.date = new Date(erolldate);
          this.date.setDate( this.date.getDate() +crfdata.result[i].fromDay);
          crfRes.fromDay=crfdata.result[i].fromDay;
          this.dates = new Date(erolldate);
          this.dates.setDate( this.dates.getDate() +crfdata.result[i].toDay);
          crfRes.toDay=crfdata.result[i].toDay;
          crfRes.userStatus=crfdata.result[i].userStatus
          localStorage.setItem('userstatus', JSON.stringify(crfRes.userStatus));
          crfRes.visitName=crfdata.result[i].visitName
          crfRes.doctorStatus=crfdata.result[i].doctorStatus
          localStorage.setItem('doctorstatus', JSON.stringify(crfRes.doctorStatus));
          crfRes.createdDate=crfdata.result[i].createdDate
          this.crfResArray.push(crfRes);
          console.log(this.crfResArray)
          console.log(this.crfResArray)
          
         }
        })
     }
     
   open(content:any) {
     this.modalService
       .open(content, { ariaLabelledBy: "modal-basic-title" })
   }
      
   opens(contents:any) {
    this.modalService
      .open(contents, { ariaLabelledBy: "modal-basic-title" })
      
  }
   onSubmit(){
     console.log("test")
     if (this.Form.valid ) {
       console.log(" Value is : " +this.Form.value.remarks );
       this.dataremarks = this.Form.value.remarks;
       console.log(this.dataremarks)
       let logiResoutputdata2 = JSON.parse(localStorage.getItem("datas")|| '{}');
       this.dropParticipantRes.userId=logiResoutputdata2.result.userId;
       console.log(  this.dropParticipantRes.userId);
      this.dropParticipantRes.patientId=this.patientId;
      console.log(this.dropParticipantRes.patientId)
       this.dropParticipantRes.remarks=  this.dataremarks;
       this.apiSevice.dropparticipantmethod( this.dropParticipantRes)
       .subscribe(data=>{
         console.log(data);
         this.dropParticipantdata=data;  
         if (data.status == 'Success'){
           alert(data.message)
           this.route.navigate(['participant']);
             
         }
        })
     }else{
       alert("Please Enter Message")
     }
   }
   handleClear() {
    this.Form.reset();
   
  }
   //For Update medication Show form
   Update(value:any){
     console.log(value)
     sessionStorage.setItem('UpdateMediForm', JSON.stringify(value));
     this.route.navigate(['ParticipantDairy']);

   }
 }
