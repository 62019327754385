<!DOCTYPE html>
<html lang="en">

<head>

  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
</head>

<body>
<app-header></app-header>
 <br>
  <div class="container-fluid">
    <div class="row" style="margin-left:-4px;">
      <div class="col-md-3">
        <a href="#" [routerLink]="['/dashboard']" style="color:black">Home / </a>
        <a href="#" [routerLink]="['/myprofile']" class="participant">My Profile </a>
      </div>
     
    </div>
  </div>
  <div class="container-fluid">
    <div class="row ">
      <div class="col-sm-3 sidenav">
        <div class="flex_row" style="text-align: center;">
          <div class="article onRight flex_row">
            <div class="image">
              <img class="rounded-circle" alt="100x100" [src]="this.loginPicture" style="object-fit: scale-down;">
            </div>
            <div calss="text-center" class="imagedata">
              <h6 style="margin: 9px;">{{logindisplayName}}</h6>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-9" style="margin-top:23px;">
        <div class="row" class="tableheight">
          <table class="table table-dark" id="t01">
            <tbody>
             <tr>
                <td> First Name</td>
                <td class="line">{{firstName}}</td>
              </tr>
              <tr>
                <td> Last Name</td>
                <td class="line">{{lastName}}</td>
              </tr>
                <tr>
                <td>Date Of Birth</td>
                <td class="line">{{this.dob }}</td>
              </tr>
              <tr>
                <td>Gender</td>
                <td class="line">{{this.gender}}</td>
              </tr>
             
              <tr>
                <td>Medical Council</td>
                <td class="line">{{this.MedicalCouncil}}</td>
              </tr>
              <tr>
                <td>Mobile Number</td>
                <td class="line">{{this.mobileNumber}}</td>
              </tr>
              <tr>
                <td>Email Id</td>
                <td class="line">{{ this.emailId}}</td>
              </tr>
              <tr>
                <td>Registration Year</td>
                <td class="line">{{ this.registration}}</td>
              </tr> 
             
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div clas="row" >
      <div class="col-sm-6"></div>
      <div class="col-sm-6" style="float: right ;border:1px solid #646668">
      <h6 style="color:#211870;">
      <p style="color:#646668">Location:</p>{{this.locations}}</h6>
      </div>
    </div>  
  </div>
  

</body>

<br>
<br>

</html>
