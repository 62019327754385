
import { Component, OnInit,VERSION,Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginResponseService } from '../loginresponeservice';
import { ApiService } from '../api.service';
import { StudyModelRequest } from '../studymodelrequst';
import { StudyDocumentModel } from '../studyDocumentModel';
import { Router } from "@angular/router";

@Component({
  selector: 'app-study-documents',
  templateUrl: './study-documents.component.html',
  styleUrls: ['./study-documents.component.css']
})

export class StudyDocumentsComponent  {
    studyDocumentModels : StudyDocumentModel;
    name = "Angular " + VERSION.major;
    myimage='../assets/images/pdf.jpg';
    studyId:any;
    studyModelRequest=new StudyModelRequest();
  
    studyDocumentModelArray:StudyDocumentModel[]=[];
    constructor(private modalService: NgbModal,
      private loginResponseService:LoginResponseService,
      private apiSevice: ApiService,private route:Router){}
    open(content:any, studyDocumentModels:StudyDocumentModel) {
    this.studyDocumentModels = studyDocumentModels;
      this.modalService.open(content, { size: 'lg' });
    }
    ngOnInit(){
      let studyId = JSON.parse(localStorage.getItem("studyId")|| '{}');
      console.log(studyId);
      let logiResoutputdata2 = JSON.parse(localStorage.getItem("datas")|| '{}');
      this.studyModelRequest.userId=logiResoutputdata2.result.userId;
      this.studyId = studyId;
      this.apiSevice.studyDocdumentMethod(this.studyModelRequest,this.studyId)
      .subscribe(studydata=> {
        console.log(studydata.result[0].studyId);
        for(let i=0;i<studydata.result.length;i++){
         var studyDocumentModel=new StudyDocumentModel();
        studyDocumentModel.studyId=studydata.result[i].studyId;
        console.log(studyDocumentModel.studyId);
        studyDocumentModel.createdDate=studydata.result[i].createdDate;
        studyDocumentModel.fileUrl=studydata.result[i].fileUrl;
        studyDocumentModel.title=studydata.result[i].title;
        console.log(studyDocumentModel.title);
        studyDocumentModel.fileType=studydata.result[i].fileType;
        this.studyDocumentModelArray.push(studyDocumentModel);
      }
        console.log(this.studyDocumentModelArray);
      },(error) => {console.log(error)}
      );
  }
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('datas');
    this.route.navigate(['login']);
  }
}