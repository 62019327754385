export class ParticipantModelResponse{
    "adverseEvents":String;
   "createdDate": Number;
    "dairyId": Number;
    "dayNumber": Number;
   "magenesiumSE": String;
    "magnesiumTaken": String;
    "patientId": Number;
    "remarks": Number;
    "studyId": Number;
    "userId": Number;
    "vitaminDSE": String;
    "vitaminDTaken": String;
    "vitaminK2SE":String;
    "vitaminK2Taken": String;
}