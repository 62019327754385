<!DOCTYPE html>
<html>

<head>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">

  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css	" />
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
  <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.bundle.min.js"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>

  <style>

  </style>
</head>

<body>

  <div class="row d-flex ">
    <div class="col-lg-6">
      <div class="card1">
        <div class="row-4=3">
          <img [src]="loginImage" class="loginImage" alt="Landing">
        </div>
      </div>
    </div>

    <div class="col-lg-6 ">
      <div class="text-center">

      </div>
      <div class="card2 card border-0 px-4 py-5">


        <div class="text-center" style="margin-top: 50px;">

          <h6 class="font-weight-lighter"> PIN GENERATION </h6>

        </div>

        <br>
        <div class="row px-3"> <label class="mb-1">
          </label> <input class="mb-4" type="text" name="email" placeholder="Enter Mobile Number">
        </div>
        <div class="row px-3"> <label class="mb-1">
          </label> <input class="mb-4" type="password" name="password" placeholder="New Pin">
        </div>
        <div class="row px-3"> <label class="mb-1">
          </label> <input type="password" name="password" placeholder=" Confirm New Pin">
        </div>
        <br>


        <div class="text-center">
          <button class="btn btn-orange text-center" [routerLink]="['/login']">SUBMIT</button>
        </div>
      </div>
    </div>

  </div>

</body>

</html>