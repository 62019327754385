import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pin',
  templateUrl: './pin.component.html',
  styleUrls: ['./pin.component.css']
})
export class PinComponent implements OnInit {

  loginImage = '../assets/images/landing_left.jpg';
  constructor() { }

  ngOnInit() {
  }

}
