
<!DOCTYPE html>
<html>
<head>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
</head>
<body>
 <app-header></app-header>
<br>
  <div class="container-fluid">
    <div class="row ml-2">
          <a href="#" [routerLink]="['/dashboard']" style="color:black">Home /</a> 
            <a href="#" [routerLink]="['/participant']" style="color:black;">Participant Status </a> 
            <a href="#" [routerLink]="['/participant']" class="study">/ CRF View </a> 
          </div>
        </div>
        <br>
        <div class="container-fluid" >
          <div class="row">
               <div class="col-md-9" >
                <p>Study Name:<b> {{this.studyName}}</b></p>
               </div>
               <div class="col-md-3" >
                <P>Participant Id :<b>{{this.patientId}}</b></P>
               </div>
               <div class="col-md-3" >
                <P> Enrolled Date Time:<b>{{this.enrolleddate}}</b></P>
               </div>
                <div class="col-md-2" >
                  <p>Protocol : <b>{{this.protocolId}}</b></p>
                  </div>
                  <div class="col-md-3" >
                  <p>Total Visits:<b> {{this.crfdatalength}}</b></p>
                 </div>
              
              </div>
           </div>
             <div class="container-fluid">
                <div class="row" style="color: whitesmoke;" >
                  <div *ngFor="let visit of crfResArray">
                  <div class="col-sm-5"  >
                    <div class ="box-example1"
                    [ngStyle]="{'background-color': (visit.userStatus === 'SUBMITTED') ? '#008000'   :(visit.userStatus === 'OPEN') ? '#eb8a17' :'' }" >
                    <p> Visit Name:{{visit.visitName}} </p>
                    <p>Fill Status:{{visit.userStatus}} </p>
                    <a href="#" [routerLink]="['/CRFDetails']" class="view" >VIEW</a> 
                    <p class="date"> Visit Date:{{ date | date: 'yyyy-MM-dd' }}-{{dates | date: 'yyyy-MM-dd'}} </p>
                    <p>Doctor Approval:{{visit.doctorStatus}} </p>
                    </div>
                  </div>
                  
                 </div>
                </div>
                
              </div>
              <br>
              <div class="container-fluid bg-3 text-center" class="threeboxes" >    
                <div class="row">
                  <div class="col-md-4"(click)="open(content)" >
                    <code class="dropparticipant"><b>DROP PARTICIPANT</b></code> 
                  </div>
                  <!-- <div class="col-md-4" *ngIf="showlabel"> 
                    <code [routerLink]="['/ParticipantDairy']" class="viewparticipantdairy"><b>VIEW PARTICIPANT DIARY</b></code> 

                  </div> -->
                  <div class="col-md-4" > 

                    <code (click)="opens(contents)"class="viewparticipantdairy"><b>VIEW PARTICIPANT DIARY</b></code> 
                  </div>
                  <div class="col-md-4" > 
                    <code [routerLink]="['/Labreports']"class="checkandreports">CHECK & ADD LAB REPORTS</code> 
                   </div>
                </div>
              </div>
              <ng-template #content let-modal>
                <!-- <div class="modal-header" style="background-color: orange;">
                  <div class="title">
                    INFORMED CONSENT FORM
                  </div>
                   
                      </div> -->
                      <div class="modal-body" >
                        <p>Participant Id: {{this.patientId}}</p>
                        <h6>Are you sure to Drop-Out patient</h6>
                        <form  [formGroup]="Form" >
                          <fieldset class="scheduler-border">
                            <legend class="scheduler-border">Enter Your Message below</legend>
                            <input id="firstname" name="remarks" class="form-control" style="border:white" formControlName="remarks" >
                          </fieldset>
                          <div >
                            <p class="modalBody" (click)="onSubmit()"  (click)="modal.dismiss('Cross click')">YES</p>
                            <p  class="modalBody"  (click)="modal.dismiss('Cross click')" (click)="handleClear()">NO</p>

                            </div>
                          </form>
                   </div>
              </ng-template>
              <ng-template #contents let-modal>
                <!-- <div class="modal-header" style="background-color: orange;">
                  <div class="title">
                    INFORMED CONSENT FORM
                  </div>
                   
                      </div> -->
                      <div class="modal-body" >
                        <h6><b>Select Option</b></h6>
                        <p>Please Select Below Option</p>
                       
                            <p style="color: rgb(24, 24, 112);"class="modalBody" (click)="Update('UpdateMedication')"  (click)="modal.dismiss('Cross click')">UPDATE MEDICATION</p>
                            <p  style="color: rgb(24, 24, 112);" class="modalBody" (click)="Update('ViewMedication')" (click)="modal.dismiss('Cross click')">VIEW MEDICATION</p>

                           
                   </div>
              </ng-template>
    
 </body>

 </html>