import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  loginName:any;
  constructor(private router: Router) { }

  ngOnInit(): void {
    let logiResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');

    //which user is login
    if (logiResoutputdata.result.roleId == '5') {
      this.loginName = "INVESTIGATOR";
    }
    else if (logiResoutputdata.result.roleId == '14') {
      this.loginName = "CO-INVESTIGATOR";
    }
    else if (logiResoutputdata.result.roleId == '13') {
      this.loginName = "CO-ORDINATOR";
    }
   // localStorage.setItem('loginName', JSON.stringify(this.loginName))
    
  }
  helpmethod(){
    this.router.navigate(['help']);
   }
  logout() {
    // remove user from local storage to log user out
    sessionStorage.removeItem('logindatas');
    //localStorage.setItem("logindatas",'');
    this.router.navigate(['login']);
  }
}
