import { Injectable } from '@angular/core';
import{LoginResponseModel} from'./loginmodelresponse';

@Injectable({providedIn:'root'})
export class LoginResponseService {
  loginRes2:any;
  data:any;
  studyDocumentRes:any;

  constructor() {}

    myMethod(data) { 
      this.data=data;
    }
      //after login msg is saved
      responeLogin(loginRes:LoginResponseModel):string {
        console.log("value"+loginRes.userId)
        this.loginRes2=loginRes;
        console.log(this.loginRes2)
        return "success"
      }

      ngOnInit(): void {
      }
      
   }