<!DOCTYPE html>
<html lang="en">

<head>

  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>

  <style>
  </style>
</head>

<body>
  <app-header></app-header>
  <br>
  <div class="container-fluid" >
    <div class="row">
        <div class="col-md-6">
          <a href="#" [routerLink]="['/dashboard']"  class="home">Home /</a> 
          <a href="#" [routerLink]="['/participant']" class="home">Participant Status</a> 
          <a href="#" [routerLink]="['/CRFView']" class="home">/ CRF View</a> 

          <a href="#" [routerLink]="['/Labreports']" class="study">/ Lab Reports</a> 

        </div>  
      
    </div>
  </div>
 <br>
   <div class="container-fluid">
              <div class="row">
                <div class="col-sm-2">
                  <h6 class="participant">Lab Reports  List </h6>
                </div>
                <div class="col-sm-8" style="padding: 11px 0px 0px 0px;">
                  <a href="#" [routerLink]="['/NewLabreports']" style="color:#0071ad">+ADD NEW LAB REPORTS</a>
                </div>
               
                <div class="col-md-12" style="margin-top: -8px;">
                  <table class="table table-dark" id="t01">
                    <thead>
             <tr style="background-color: darkgrey;">
                 <th> Test Name</th>
                 <th>Test Value</th>
                 <th>Created Date</th>
                 <th>File URL</th>
             </tr>
          </thead>
       <tbody >
          <tr>

            <tr *ngFor="let labReportsModels of  labReportsModel ">
              <td> {{labReportsModels.testName}} </td>
              <td> {{labReportsModels.testValue}} </td>
              <td> {{labReportsModels.createdDate}} </td>
              <td>
                <p style="cursor: pointer;" (click)="open(content,labReportsModels)">ViewImage</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <ng-template #content let-modal>
    <div class="modal-header" style="background-color: orange;">
      <p (click)="modal.dismiss('Cross click')" style="cursor: pointer;color:white;
      font-size: 12px; border: 1px solid;padding: 2px 16px; float: right;">CLOSE </p>
    </div>
    <div class="modal-body">

      <img src="{{labReportsModels.fileURL}}" style="max-width: 100%;">
    </div>
  </ng-template>

</body>


</html>