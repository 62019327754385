import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LoginModelRequest } from './loginmodelrequest';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { ParticipantModel } from './participantmodel'
import { StudyModelRequest } from './studymodelrequst';
import { catchError } from 'rxjs/operators';
import { NewParticipantModelReq } from './newparticipantreq';
import { DropParticipantResponse } from './droparticipantresponse';
import { CrfVisitrequest } from './CRFVisitRequest';
import { ParticipantRegistrationResponse } from './participantregistrationresponse';
import { ParticipantDaiaryRequest } from './participantdairyrequest';
import { AdverseModel } from './adversemodel';
import { AdverseModelRequest } from './adversemodelrequest';
import { AddLabReportsModel } from './addlabreportsmodel';
import{ ParticipantScreeningModel} from './participantscreeningmodel'
import{ApproveModel} from './approvemodel';
import { CrfDetailsModel } from './Crfdetailsmodel';
import { ApprovePatient } from './ApprovePatienModel';

@Injectable({ providedIn: 'root' })
export class ApiService {
  // baseURL: any = "http://trials.cliniv.in/";
  // appName: any = "ClinIVUAT/";
  baseURL: any = "https://trials.cliniv.in/";
  appName: any = "ClinIVDemo/";
  constructor(private http: HttpClient) { }

  //login post method
  authenticateLogin(loginReq: LoginModelRequest): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(loginReq);
    console.log(body)
    var loginUrl = this.baseURL + this.appName + "login/authenticate";
    return this.http.post(loginUrl,
            body, { 'headers': headers })
  }

  // study detail get method
  studyDetailMethod(studyModelRequest: StudyModelRequest): Observable<any> {
    var url = this.baseURL + this.appName + "study/getAllStudysByUser?userId=";
  //  const header = new HttpHeaders().set('Authorization', 'Bearer' + studyModelRequest.message);
    url = url + studyModelRequest.userId;
    //url = url + "&docId=" + studyModelRequest.otherId;
    console.log("hi" + url);
    return this.http.get(url);
  }

  studyDocdumentMethod(studyModelRequest: StudyModelRequest, data: Number): Observable<any> {
    var url1 = this.baseURL + this.appName + "study/getStudyDocumentsById?studyId=";
    url1 = url1 + data;
    url1 = url1 + "&type=" + "ALL";
    url1 = url1 + "&userId=" + studyModelRequest.userId;
    return this.http.get<any>(url1).pipe(catchError(this.handleError))
  }
  private handleError(error: Response) {
    return Observable.throw(error);
  }

  //participant data  url
 // patient/getAllPatientsByStudyId?doctorId=1&studyId=1&role=5&userId=1
  participantmethod(participantModel: ParticipantModel): Observable<any> {
    var participanturl = this.baseURL + this.appName + "patient/getAllPatientsByStudyId";
    participanturl = participanturl + "?doctorId=" + participantModel.doctorId;
    participanturl = participanturl + "&studyId=" + participantModel.studyId;
    participanturl = participanturl + "&role=" + participantModel.roleId;
    participanturl = participanturl + "&userId=" + participantModel.userId;
    return this.http.get(participanturl);
  }

  //new participant cretreraia list
  newparticipantmethod(newParticiReq: NewParticipantModelReq): Observable<any> {
    var newparticipanturl = this.baseURL + this.appName + "study/getInExDataByStudyId";
    newparticipanturl = newparticipanturl + "?studyId=" + newParticiReq.studyId;
    newparticipanturl = newparticipanturl + "&userId=" + newParticiReq.userId;
    return this.http.get(newparticipanturl);
  }

  //dropparticipant status method
  dropparticipantmethod(dropParticipantRes: DropParticipantResponse): Observable<any> {
    var dropparticipanturl = this.baseURL + this.appName + "patient/terminatePatient";
    dropparticipanturl = dropparticipanturl + "?patientId=" + dropParticipantRes.patientId;
    dropparticipanturl = dropparticipanturl + "&remarks=" + dropParticipantRes.remarks;
    dropparticipanturl = dropparticipanturl + "&userId=" + dropParticipantRes.userId;
    return this.http.get(dropparticipanturl);
  }
  //Visitdetails
  Visitmethod(crfVisitreq: CrfVisitrequest): Observable<any> {
    var VisitmethodUrl = this.baseURL + this.appName + "patient/getPatientVisitsByStudy";
    VisitmethodUrl = VisitmethodUrl + "?patientId=" + crfVisitreq.patientId;
    VisitmethodUrl = VisitmethodUrl + "&studyId=" + crfVisitreq.studyId;
    VisitmethodUrl = VisitmethodUrl + "&userId=" + crfVisitreq.userId;
    console.log(VisitmethodUrl)
    return this.http.get(VisitmethodUrl);
  }

  //EnrolledNewPArticipant
  EnrolledNewParticipant(participantRegistrationRes: ParticipantRegistrationResponse): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(participantRegistrationRes);
    console.log(body)
    var loginUrl = this.baseURL + this.appName + "patient/enrollNewPatient";
    return this.http.post(loginUrl,
      body, { 'headers': headers })
  }
  // http://trials.cliniv.in/ClinIVDemo/patient/getPatientDairyByStudyIdMonth?
  // patientId=12345/4567/1/1/1/28&studyId=1&fromDate=2021-06-01&toDate=2021-06-31&userId=1
  //participant daiary api
  ParticipantDaiarymethod(participantDaiaryReq: ParticipantDaiaryRequest): Observable<any> {
    var ParticipantDaiarymethod = this.baseURL + this.appName + "patient/getPatientDairyByStudyIdMonth";
    ParticipantDaiarymethod = ParticipantDaiarymethod + "?patientId=" + participantDaiaryReq.patientId;
    ParticipantDaiarymethod = ParticipantDaiarymethod + "&studyId=" + participantDaiaryReq.studyId;
    ParticipantDaiarymethod = ParticipantDaiarymethod + "&userId=" + participantDaiaryReq.userId;
    ParticipantDaiarymethod = ParticipantDaiarymethod + "&fromDate=" + participantDaiaryReq.fromDate;
    ParticipantDaiarymethod = ParticipantDaiarymethod + "&toDate=" + participantDaiaryReq.toDate;

    return this.http.get(ParticipantDaiarymethod);
  }
  //day clicked 
  ParticipantDaiarymethodsingledate(participantDaiaryReq: ParticipantDaiaryRequest): Observable<any> {
    var ParticipantDaiarymethod = this.baseURL + this.appName + "patient/getPatientDairyByStudyIdMonth";
    ParticipantDaiarymethod = ParticipantDaiarymethod + "?patientId=" + participantDaiaryReq.patientId;
    ParticipantDaiarymethod = ParticipantDaiarymethod + "&studyId=" + participantDaiaryReq.studyId;
    ParticipantDaiarymethod = ParticipantDaiarymethod + "&userId=" + participantDaiaryReq.userId;
    ParticipantDaiarymethod = ParticipantDaiarymethod + "&fromDate=" + participantDaiaryReq.fromDate;
    ParticipantDaiarymethod = ParticipantDaiarymethod + "&toDate=" + participantDaiaryReq.toDate;
    console.log(ParticipantDaiarymethod);
    return this.http.get(ParticipantDaiarymethod);

  }
  //adverse event api method
  adversemethod(adverseModel: AdverseModel): Observable<any> {
    var adversemethod = this.baseURL + this.appName + "masterdata/getUserHealthData";
    adversemethod = adversemethod + "?patientId=" + adverseModel.patientId;
    adversemethod = adversemethod + "&userId=" + adverseModel.userId;
    console.log(adversemethod);
    return this.http.get(adversemethod);
  }
  //update post medication
  upadatemethod(adverseModelReq: AdverseModelRequest): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(adverseModelReq);
    console.log(body)
    var updateUrl = this.baseURL + this.appName + "patient/submitMedication";
    return this.http.post(updateUrl,
      body, { 'headers': headers })
  }
  //drug stock api method
  drugstockmethod(adverseModel: AdverseModel): Observable<any> {
    var drugstockmethod = this.baseURL + this.appName + "product/getAllDrugStockByPatient";
    drugstockmethod = drugstockmethod + "?patientId=" + adverseModel.patientId;
    drugstockmethod = drugstockmethod + "&userId=" + adverseModel.userId;
    console.log(drugstockmethod);
    return this.http.get(drugstockmethod);
  }
  //labreports api method
  labreportsmethod(adverseModel: AdverseModel): Observable<any> {
    var labreportsmethod = this.baseURL + this.appName + "patient/getLabReports";
    labreportsmethod = labreportsmethod + "?userId=" + adverseModel.userId;
    labreportsmethod = labreportsmethod + "&patientId=" + adverseModel.patientId;
    console.log(labreportsmethod);
    return this.http.get(labreportsmethod);
  }
  //add LabReports api method
  addlabreportsmethod(addLabReportsModel: AddLabReportsModel): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(addLabReportsModel);
    console.log(body)
    var updatelabUrl = this.baseURL + this.appName + "patient/updateLabReports";
    return this.http.post(updatelabUrl,
      body, { 'headers': headers })
  }
  //participant list open then navigate to screenin patient api
  patientscreening(participantScreeningModel: ParticipantScreeningModel): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(participantScreeningModel);
    console.log(body)
    var patientopenupdateUrl = this.baseURL + this.appName + "patient/updatePatientLab";
    return this.http.post(patientopenupdateUrl,
      body, { 'headers': headers })
  }
  //labreports api method2
  inprogresssmethod(adverseModel: AdverseModel): Observable<any> {
    var inprogresssmethod = this.baseURL + this.appName + "patient/getLabReports";
    inprogresssmethod = inprogresssmethod + "?userId=" + adverseModel.userId;
    inprogresssmethod = inprogresssmethod + "&patientId=" + adverseModel.patientId;
    console.log(inprogresssmethod);
    return this.http.get(inprogresssmethod);
  }
  //approve patient api 
  approvepatientmethod(approveModel:ApproveModel):Observable<any>{
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(approveModel);
    console.log(body)
    var approveUrl = this.baseURL + this.appName + "patient/approvePatientICF";
    return this.http.post(approveUrl,
      body, { 'headers': headers })
  }
  //crf visit details api
  crfVisitmethod(crfDetailsModel:CrfDetailsModel): Observable<any> {
     var crfVisitmethod = this.baseURL + this.appName + "patient/getPatientVisitsByVisit";
    //var crfVisitmethod="http://trials.cliniv.in/ClinIVDemo/patient/getPatientVisitsByVisit"
    crfVisitmethod = crfVisitmethod + "?studyId=" + crfDetailsModel.studyId;
    crfVisitmethod = crfVisitmethod + "&patientId=" + crfDetailsModel.patientId;
    crfVisitmethod = crfVisitmethod + "&visitId=" + crfDetailsModel.visitId;
    crfVisitmethod = crfVisitmethod + "&userId=" + crfDetailsModel.userId;
    console.log(crfVisitmethod);
    return this.http.get(crfVisitmethod);
  }
  //submit visit data
  visitmethod(data:any):Observable<any>{
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    console.log(body)
    var visitUrl = this.baseURL + this.appName + "question/submitQuestions";
    return this.http.post(visitUrl,
      body, { 'headers': headers })
  }

   //myprofile data  url
   myprofilemethod(myprofile:any): Observable<any> {
    var myprofileturl = this.baseURL + this.appName + "doctor/getMyProfile";
    myprofileturl = myprofileturl + "?userId=" + myprofile;
    console.log(myprofileturl)
    return this.http.get(myprofileturl)
  }
  //  //Approve patient list CRF data  url
  //  ApprovePatientmethod(approvePatient:ApprovePatient): Observable<any> {
  //   var Approveurl = this.baseURL + this.appName + "patient/approvePatientVisit";
  //   Approveurl = Approveurl + "?studyId=" + approvePatient.studyId;
  //   Approveurl = Approveurl + "&doctorId=" + approvePatient.doctorId;
  //   Approveurl = Approveurl + "&doctorRemarks=" + approvePatient.doctorRemarks;
  //   Approveurl = Approveurl + "&patientId=" + approvePatient.patientId;
  //   Approveurl = Approveurl + "&studyId=" + approvePatient.studyId;

  //   return this.http.get(Approveurl)
  // }
    //Approve patient list CRF data  url
    ApprovePatientmethod(approvePatient:ApprovePatient): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(approvePatient);
    console.log(body)
    var visitUrl = this.baseURL + this.appName + "patient/approvePatientVisit";
    return this.http.post(visitUrl,
      body, { 'headers': headers })
  }
}
