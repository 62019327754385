import { Component, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

import { LoaderService } from './LoaderService';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'cliniv-web';
  showHead: boolean = false;
  isLoading: Subject<boolean> = this.loader.isLoading;
  ngAfterContentChecked(): void {
    this.changesDetect.detectChanges();
  }

  constructor(private router: Router,private loader: LoaderService, private http: HttpClient, private changesDetect: ChangeDetectorRef) {
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event['url'] == '/') {
          this.showHead = false;
        } else if (event['url'] == '/login') {
          this.showHead = false;
        }  else if (event['url'] == '/pin') {
          this.showHead = false;
        } 
        else {
          this.showHead = true;
        }
      }
    });
    
    //this method is for all screen starts scrool zero  for evey component
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
  }
  onRightClick() {
    alert("Right Click Not Allowed!!")
    return false;
    //return true;
  }
}

