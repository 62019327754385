<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>

</head>


<body>
  <app-header></app-header>
  <br>
  <div class="container-fluid">
    <div class="row" style="margin-left: 1%;">
      <a href="#" [routerLink]="['/dashboard']" style="color:black">Home /</a>
      <a href="#" [routerLink]="['/participant']" style="color:black">Participant Status /</a>
      <a href="#" [routerLink]="['/CRFView']" style="color:black">CRF View /</a> 

      <a href="#" [routerLink]="['/Labreports']" style="color:black">  Lab Reports /</a>
      <a href="#" [routerLink]="['/NewLabreports']" class="study">New Lab Report </a>
    </div>
  </div>
  <br>
  <div class="container-fluid">
    <div class="row" style="margin-left: 1%;">
      <div>
        <a href="#" [routerLink]="['/Labreports']" style="color:black">Lab Reports </a>

        <!-- <p class="list">Participant List</p> -->
      </div>
      <div class="addnew">
        <h6 class="new">+ADD New Lab Reports </h6>
      </div>
    </div>
  </div>
  <br>
  <div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
          <form [formGroup]="Form" (ngSubmit)="onSubmit()">
            <!-- <div>
              <input class="form-control" placeholder="Test Name*" formControlName="testname" required>
            </div>
            <br>
            <div>
              <input class="form-control" placeholder="Test value*" formControlName="testvalue" required>
            </div> -->
            <mat-form-field appearance="outline">
              <mat-label>Test Name </mat-label>
              <input matInput placeholder="Test Name*" formControlName="testname" required>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Test value </mat-label>
              <input matInput placeholder="Test value*" formControlName="testvalue" required>
            </mat-form-field>
            <br>
            <div>
              <input type="file" (change)="onChange($event)" formControlName="image">
            </div>
            <button class="btn btn-orange text-center" 
              >SUBMIT</button>
          </form>
        </div>
      </div>
  </div>

</body>

</html>