export class ParticipantRegistrationResponse{
    "studyId": Number;
    "doctorId": Number;
    "patientId":any;
    "firstName": String;
    "lastName": String;
    "gender": String
    "mobileNumber": Number;
    "emailId":String;
    "icfSignType":String;
    "trailSiteId": Number;
    "age": Number;
    "aadharNumber":Number;
    "location": String;
    "enrolledUserId":any;
    "icfSignURL":String;
    "proofImageUrl": String;

}