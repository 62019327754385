<!DOCTYPE html>
<html>

<head>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
</head>

<body>
  <app-header></app-header>
  <br>
  <div class="container-fluid">
    <div class="row ml-2">

      <a href="#" [routerLink]="['/dashboard']" style="color:black">Home /</a>

      <a href="#" [routerLink]="['/participant']" style="color:black;">Participant Status </a>

      <a href="#" [routerLink]="['/CRFView']" style="color:black;">/ CRF View</a>

      <a href="#" [routerLink]="['/ParticipantDairy']" style="color:black;">/ Participant Diary</a>
      <a href="#" [routerLink]="['/updatemedicine']" class="study">/ Update Medication</a>

    </div>
  </div>
  <br>
  <div class="container-fluid">
    <div class="row ml-2">
      <P>Participant Id : {{this.patientId}}</P>
    </div>
  </div>
  <div class="container-fluid" *ngIf="Form1">
    <div class="row">

      <div class="col-md-12">
        <mat-form-field appearance="fill">
          <mat-label>Selected Date*</mat-label>
          <input matInput style="color: rgb(219, 66, 66);" value="{{ this.madecinedate| date: 'dd-MM-yyyy'}}"
            readonly>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Vitamin D Adverse Events</mat-label>
          <input matInput readonly>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label> Vitamin K2-7 Adverse Events</mat-label>
          <input matInput readonly>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Magnesium Glyncinate Adverse Events</mat-label>
          <input matInput readonly>
        </mat-form-field>
        </div>
        </div>
        </div>
        
  <div class="container-fluid"  *ngIf="Form2">
    <form [formGroup]="Form">

      <div class="row">

        <div class="col-md-12">
          <mat-form-field appearance="fill">
            <mat-label>Selected Date*</mat-label>
            <input matInput style="color: rgb(219, 66, 66);" value="{{ this.madecinedate| date: 'dd-MM-yyyy'}}"
              readonly>
          </mat-form-field>

          <label class="container">
            <input value="check" type="checkbox" formControlName="vitaminK2selected">Vitamin K2-7 Taken
            <span class="checkmark"></span>
          </label>
          <mat-form-field appearance="fill">
            <mat-label>Vitamin K2-7 Adverse Events*</mat-label>
            <input matInput formControlName="vitamink2adverse">
          </mat-form-field>
          <label class="container" *ngIf="showLabel">
            <input value="check" type="checkbox" formControlName="Magnesiumselected">vitamin D Taken
            <span class="checkmark"></span>
          </label>
          <mat-form-field appearance="fill"*ngIf="showLabel">
            <mat-label>vitamin D*</mat-label>
            <input matInput formControlName="Magnesiumadverse">
          </mat-form-field>
          <!-- <label></label> -->
          <mat-form-field appearance="fill">
            <mat-label>Blood Pressure*</mat-label>
            <input matInput placeholder="Blood Pressure*" formControlName="bloodPressure"
             >
          </mat-form-field>
          <!-- <mat-form-field appearance="fill">
            <mat-label>Diastolic</mat-label>
            <input matInput placeholder="Diastolic*" type="number" formControlName="diastolic" autocomplete="off">
          </mat-form-field> -->
          <mat-form-field appearance="fill">
            <mat-label>spo2*</mat-label>
            <input matInput  type="number" formControlName="Respiratory">
          </mat-form-field>
          <br>
          <label class="adverse">ADVERSE EVENTS</label>

          <mat-form-field appearance="fill" (click)="open(content)">
            <mat-label>Add Adverse Events*</mat-label>
            <input matInput value="{{this.symptom}}" readonly>
          </mat-form-field>
          <!-- <div *ngFor="let item of selectedItemsList">{{item.title}}</div> -->
        </div>
      </div>
      <br>
      <button class="btn btn-orange text-center" (click)="Medicationdata()">SUBMIT</button>
      <br>
    </form>
  </div>
  <br>
  <br>

  <ng-template #content let-modal>
    <div class="modal-header" style="background-color: orange;">
      <div style="margin-left: 27%;color: white;">
        SELECT ADVERSE EVENTS
      </div>
      <p style="color:white;margin-top:0.5%;background-color: orange;" style="cursor: pointer;"
        (click)="modal.dismiss('Cross click')">CLOSE </p>
    </div>
    <div class="modal-body">
      <P>Select Below Check boxes </P>
      <div class="form-group">
        <div class="row">
          <table class="table">
            <thead>
            </thead>
            <tbody>
              <tr *ngFor="let item of  AdverseModelReasponse">
                <label class="container">
                  <p>{{item.title}}</p>
                  <input value="{{item.check}}" type="checkbox" [(ngModel)]="item.isChecked"
                    (change)="changeSelection(item)">
                  <span class="checkmark"></span>
                </label>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <button class="btn btn-orange text-center" style="float:right"
          (click)="modal.dismiss('Cross click')" (click)="symtomMethod()">SUBMIT</button>
      </div>
    </div>
  </ng-template>
</body>


</html>