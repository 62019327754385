import { Component, OnInit } from '@angular/core';
import{ParticipantModel}from'../participantmodel';
import { ApiService } from '../api.service';
import {Router} from "@angular/router";

@Component({
  selector: 'app-participant',
  templateUrl: './participant.component.html',
  styleUrls: ['./participant.component.css']
})
export class ParticipantComponent implements OnInit {
  searchText:any;
    patientId:any;
  constructor(private apiSevice: ApiService,private route:Router) { }
   participantModel=new ParticipantModel();
    participantRess:any = [];
    patientenrolleddate:Number;
   ngOnInit() {
    let logiResoutputdata2 = JSON.parse(localStorage.getItem("datas")|| '{}');
    this.participantModel.userId=logiResoutputdata2.result.userId;
    this.participantModel.roleId=logiResoutputdata2.result.roleId;
    this.participantModel.doctorId=logiResoutputdata2.result.otherId;
    let studyId = JSON.parse(localStorage.getItem("studyId")|| '{}');
    console.log(studyId);
    this.participantModel.studyId = studyId;
    this.apiSevice.participantmethod(this.participantModel)
    .subscribe(data=>{
      console.log(data);
      this.participantRess=data.result;
      console.log(this.participantRess[0].patientId)
      localStorage.setItem('paticipantlength',JSON.stringify(this.participantRess));
      })
      }
      //Id click after Id display details in Crf view
      getdetailsId(e:any) {
        console.log(e)
        if (e.status == 'ACTIVE') {
          this.route.navigate(['CRFView']);
        } else if(e.status == 'OPEN'){
          this.route.navigate(['Patientscreening']);

        }else if (e.status == 'DROP_OUT'){
          alert("You Can't access this Patient (Dropped).")
        }else if(e.status=='IN-PROGRESS'){
          this.route.navigate(['Approvepatient'])
        }
        console.log(e.patientId)
        console.log(e.mobileNumber)
        localStorage.setItem('singlepatientfulldata',JSON.stringify(e));
        localStorage.setItem('mobileNumber',JSON.stringify(e.mobileNumber));
        this.patientenrolleddate=e.enrolledDate;
        console.log(this.patientenrolleddate);
        localStorage.setItem('enrolleddate',JSON.stringify(this.patientenrolleddate));
        this.patientId=e.patientId
        console.log(this.patientId)
        localStorage.setItem('datass', JSON.stringify(this.patientId));
      }
      logout(){
        // remove user from local storage to log user out
        localStorage.removeItem('datas');
          this.route.navigate(['login']);
      }
    }