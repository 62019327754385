<!DOCTYPE html>
<html lang="en">

<head>
  <title>Bootstrap Example</title>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>
  <link href="https://unpkg.com/angular-calendar@0.25.2/css/angular-calendar.css" rel="stylesheet">
  <style>
    .testh {
      background-color: red !important;
      color: white !important;
    }
  </style>
</head>

<body>
<app-header></app-header>
  <br>
  <div class="container-fluid" >
    <div class="row" >
        <a href="#" [routerLink]="['/dashboard']" class="hm">Home /</a>
        <a href="#" [routerLink]="['/participant']" class="home">Participant Status </a>
        <a href="#" [routerLink]="['/CRFView']" class="home">/ CRF View</a>
        <a href="#" [routerLink]="['/ParticipantDairy']" class="home">/ Participant Diary</a>      
    </div>
  </div>
  <br>
  <div class="container-fluid">
    <div class="row" calss="side" >
      <div class="col-md-12">
        <div class="col-md-7">
          <P>Participant Id : {{this.patientId}}</P>
        </div>
        <div class="col-md-5">
          <div class="col-sm-4">
            <div class='box yellow' ></div>
            <h5 class="completed">Yet to Taken</h5>
          </div>
          <div class="col-sm-4">
            <div class='box green'></div>
            <h5 class="completed">Completed</h5>
          </div>
          <div class="col-sm-4">
            <div class='box red'></div>
            <h5 class="completed">Not Taken</h5>

          </div>
        </div>
      </div>
    </div>
  </div>
 
<div class="container">
  <div class="row" style="background-color: orange;
  margin-left: -4%;
  width: 71%;
  ">
    <div class="col-md-12">
      <div class="col-md-3">
        <div class="btn-group">
          <div class="col-md-3 ">
            <div mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
              (viewDateChange)="closeOpenMonthViewDay()">
              <p class="arrow left"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-7 text-center">
        <div class="viewdate" mwlCalendarToday [(viewDate)]="viewDate"
          style="color:#fff;margin-left:-24%; font-size: 16px; font-weight: 600;">

          {{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}

        </div>
      </div>
      <div class="col-md-2">

        <div mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
          <p class="arrow right"></p>
        </div>
      </div>
    </div>

  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-md-8">
      <div [ngSwitch]="view">
        <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [refresh]="refresh"
          (dayClicked)="dayClicked($event.day)" (beforeViewRender)="applyDateSelectionPolicy($event)"
          style="cursor: pointer;">
        </mwl-calendar-month-view>
        <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [refresh]="refresh"
          (dateClicked)="dateClicked($event.day)">
        </mwl-calendar-week-view>
        <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [refresh]="refresh">
        </mwl-calendar-day-view>
      </div>
    </div>
    <div class="col-sm-3" *ngIf="showlabel1">
      <div class=" updtae">
        <div class=" hedader">
          <p class="details">CheckDetails</p>
        </div><br>
        <P style=" text-align: center;"> Selected date:{{ this.dynamicdate | date: 'dd-MM-yyyy'}}</P>
        <br>
        <button *ngIf="showLabel" [routerLink]="['/updatemedicine']" class="action">VIEW DETAILS</button>
      </div>
    </div>
  </div>
</div>
<br>
</body>


</html>