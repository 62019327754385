import { Component, OnInit ,ViewEncapsulation} from '@angular/core';
import { Router } from "@angular/router";
import { ParticipantDaiaryRequest } from '../participantdairyrequest';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../api.service';
import { ParticipantModelResponse } from '../participantmodelrespone'
import { Subject } from 'rxjs';

import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarMonthViewDay,
  CalendarView,

} from 'angular-calendar';


@Component({
  selector: 'app-participantdairy',
  templateUrl: './participantdairy.component.html',
  styleUrls: ['./participantdairy.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ParticipantdairyComponent implements OnInit {




  participantDaiaryReq = new ParticipantDaiaryRequest();
  participantModelRes = new ParticipantModelResponse();
  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();
  startday = '01';
  endday = '31';
  dates: any
  eventtilte: any;
  patientId: any
  startdate: any;
  enddate: any;
  fromdate: any;
  lastdate: any;
  date: Date;
  data:any;
  dynamicdata: any;
  dynamicdate: any;
  adverseEvents: any;
  showlabel1:any
  showLabel:any;
  registerdate:any;
  maxdate:any;
  adverse: any = [];
  condtion:any;
  constructor(private route: Router, private modal: NgbModal, private apiSevice: ApiService) { }

  ngOnInit() {
    let erolldate = JSON.parse(localStorage.getItem("enrolleddate") || '{}');
    this.fromdate = erolldate;
    console.log(erolldate)
    console.log(this.fromdate)
    let patientduration = JSON.parse(localStorage.getItem("studydetails") || '{}');
    this.lastdate = patientduration.patientDuration
    console.log(patientduration.patientDuration)
    //participantduration add to enrolldate

    this.date = new Date(this.fromdate);
    this.date.setDate(this.date.getDate() + this.lastdate);
    this.data = this.date;
    console.log(this.date)
    ///new month and year dynamic method
    this.dates = new Date().getFullYear() + '-' + (new Date().getMonth() + 1);
    this.startdate = this.dates + '-' + this.startday
    this.enddate = this.dates + '-' + this.endday
    // participant dairy to call api for set the data
    let patientId= JSON.parse(localStorage.getItem("datass") || '{}');
    this.patientId = patientId;
    let studydata = JSON.parse(localStorage.getItem("studydetails") || '{}');
    let logiResoutputdata2 = JSON.parse(localStorage.getItem("datas") || '{}');
    this.participantDaiaryReq.patientId =patientId;
    this.participantDaiaryReq.studyId = studydata.studyId;
    this.participantDaiaryReq.userId = logiResoutputdata2.result.userId;
    this.participantDaiaryReq.fromDate = this.startdate;
    this.participantDaiaryReq.toDate = this.enddate;
    this.apiSevice.ParticipantDaiarymethod(this.participantDaiaryReq)
      .subscribe(participantDaiarydata => {
        console.log(participantDaiarydata);
        this.participantModelRes = participantDaiarydata;
        localStorage.setItem('participantdairydata', JSON.stringify(this.participantModelRes));
this.adverseEvents=participantDaiarydata.result;
        
       
for (let datum of this.adverseEvents) {
      this.adverse.push(datum.createdDate);
      localStorage.setItem('participantdairydata', JSON.stringify(participantDaiarydata));
    }
    this.refresh.next();
  })
    
  }
  // single date clicked
  dayClicked(e: any) {
    // console.log(e);
    //day only taken method
    if (e.date) {
      this.showlabel1 = true;
    } else {
      this.showlabel1 = false;
    }
    if (e.date <= new Date()) {
      this.showLabel = true;
    } else {
      this.showLabel = false;
    }
    let data = new Date(e.date);
    this.dynamicdate = data;
    console.log("hii" + this.dynamicdate)
    //dynamic day add to dynamic year
    this.dates = new Date().getFullYear() + '-' + (new Date().getMonth() + 1);
    this.dynamicdata = this.dates + '-' + this.dynamicdate;
    console.log(this.dynamicdata)
    localStorage.setItem('datamadecine', JSON.stringify(this.dynamicdate));
    // console.log(this.viewDate)
    let patientId = JSON.parse(localStorage.getItem("datass") || '{}');
    this.patientId = patientId
    let studydata = JSON.parse(localStorage.getItem("studydetails") || '{}');
    let logiResoutputdata2 = JSON.parse(localStorage.getItem("datas") || '{}');
    this.participantDaiaryReq.patientId = patientId;
    this.participantDaiaryReq.studyId = studydata.studyId;
    this.participantDaiaryReq.userId = logiResoutputdata2.result.userId;
    this.participantDaiaryReq.fromDate = this.dynamicdata;
    this.participantDaiaryReq.toDate = this.dynamicdata;
    this.apiSevice.ParticipantDaiarymethodsingledate(this.participantDaiaryReq)
      .subscribe(participantDaiarydatasingledate => {
        console.log(participantDaiarydatasingledate);

        //console.log(participantDaiarydatasingledate.result[0].adverseEvents)

      
      })
      
  }
  refresh: Subject<any> = new Subject();
  events: CalendarEvent[] = [
    
  ];
  activeDayIsOpen: boolean = true;
  


  
   //adverseevents
   dateIsAdverse(date: Date): boolean {
    var typecheck = "";
   // console.log("Data" + this.adverse);
    for (let datum of this.adverse) {
      if (date.toLocaleDateString() == new Date(datum).toLocaleDateString()) {
        typecheck = typecheck + "Yes";
       
      }
    }
    //console.log("Status"+typecheck)
    if (typecheck.includes("Yes")) {
     // console.log(typecheck)
      return true;
    }
    else{
      return false;
    }
  }
  // yello days
  dateIsRed(date: Date): boolean {
  let patientduration = JSON.parse(localStorage.getItem("studydetails") || '{}');
  let erolldate = JSON.parse(localStorage.getItem("enrolleddate") || '{}');
  this.date = new Date(erolldate);
  this.date.setDate(this.date.getDate() + patientduration.patientDuration);
    let data4 = this.date;
    //console.log("pp" + data4)
    this.condtion = data4;
    return date.getTime() <=data4.getTime();
  }
  //day show maxium method
  dateIsMax(date: Date): boolean {
   let erolldate = JSON.parse(localStorage.getItem("enrolleddate") || '{}');
   var date1 = new Date(erolldate);
   var date2 = new Date();
   var Time = date2.getTime() - date1.getTime();
   var Days = Time / (1000 * 3600 * 24);
   let data = Math.round(Days)
   this.date = new Date(erolldate);
   this.date.setDate(this.date.getDate() +data);
   this.maxdate = this.date;
   return date.getTime() >= this.maxdate.getTime();
  
  }
  //day show minimum
  dateIsMin(date: Date): boolean {
    let erolldate = JSON.parse(localStorage.getItem("enrolleddate") || '{}');
    this.registerdate = new Date(erolldate);
     this.registerdate.setDate(this.registerdate.getDate() - 1);
     return date.getTime() >= new Date(this.registerdate).getTime();
  }
   //yello days
   dateIsGray(date: Date): boolean {
    let patientDuration = JSON.parse(sessionStorage.getItem("studydetails") || '{}');
    let singlepatientfulldata = JSON.parse(sessionStorage.getItem("singlepatientfulldata") || '{}');
    this.date = new Date(singlepatientfulldata.registeredDate);
    this.date.setDate(this.date.getDate() + patientDuration.patientDuration);
    let data4 = this.date;
    this.condtion = data4;
    return date.getTime() >= data4.getTime();
  }
  
 
  // //days colors in max and mini
  applyDateSelectionPolicy({ body }: { body: CalendarMonthViewDay[] }): void {
    body.forEach(day => {
     //registeration date before days
     if (!this.dateIsMin(day.date)) {
      //day.backgroundColor = "#D3D3D3";//gray
      day.cssClass = 'gray';
    }
    else if (!this.dateIsMax(day.date)) {
      //registeration to current date between adverse events
      if (this.dateIsAdverse(day.date)) {
        day.cssClass = 'dotg';
      } else if (this.dateIsGray(day.date)) {
        //registeration+patiet duration afterdays
        // day.backgroundColor = "#D3D3D3"; //gary color
        day.cssClass = 'gray';
      }
      else if (this.dateIsRed(day.date)){
        //registeration and current date between days
        day.cssClass = 'dot';
      }else{
        day.cssClass = 'gray';

      }
    }
    //registeration+patiet duration
    else if (this.dateIsRed(day.date)) {
      //day.backgroundColor = "#FFFF00"; //yellow color
      day.cssClass = 'doty';
    }
    else {
      //registeration+patiet duration afterdays
      // day.backgroundColor = "#D3D3D3"; //gary color
      day.cssClass = 'gray';
    }
     });
   }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
    let erolldate = JSON.parse(localStorage.getItem("enrolleddate") || '{}');
    this.fromdate = erolldate;
    console.log(erolldate)
    console.log(this.fromdate)
    let patientduration = JSON.parse(localStorage.getItem("studydetails") || '{}');
    this.lastdate = patientduration.patientDuration
    //participantduration add to enrolldate

    this.date = new Date(this.fromdate);
    this.date.setDate(this.date.getDate() + this.lastdate);
    this.data = this.date;
    console.log(this.date)
    ///new month and year dynamic method
    this.dates = new Date().getFullYear() + '-' + (new Date().getMonth() + 1);
    this.startdate = this.dates + '-' + this.startday
    this.enddate = this.dates + '-' + this.endday
    // participant dairy to call api for set the data
    let patientId= JSON.parse(localStorage.getItem("datass") || '{}');
    this.patientId = patientId;
    let studydata = JSON.parse(localStorage.getItem("studydetails") || '{}');
    let logiResoutputdata2 = JSON.parse(localStorage.getItem("datas") || '{}');
    this.participantDaiaryReq.patientId =patientId;
    this.participantDaiaryReq.studyId = studydata.studyId;
    this.participantDaiaryReq.userId = logiResoutputdata2.result.userId;
    this.participantDaiaryReq.fromDate = this.startdate;
    this.participantDaiaryReq.toDate = this.enddate;
    this.apiSevice.ParticipantDaiarymethod(this.participantDaiaryReq)
      .subscribe(participantDaiarydata => {
        console.log(participantDaiarydata);
        this.participantModelRes = participantDaiarydata;
        localStorage.setItem('participantdairydata', JSON.stringify(this.participantModelRes));

        this.adverseEvents=participantDaiarydata.result;
        
       
        for (let datum of this.adverseEvents) {
              this.adverse.push(datum.createdDate);
            }
            this.refresh.next();
          })
  }


  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('datas');
    this.route.navigate(['login']);
  }
 

}
