export class CrfResponse{
        "studyId": String;
        "visitId": Number;
        "patientId": Number;
        "doctorId": Number;
        "fromDay": Number;
        "toDay": Number;
        "userStatus": Number;
        "visitName": String;
        "doctorStatus": String;
        "createdDate": String;
      }