<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>

</head>


<body>
  <app-header></app-header>
  <br>
  <div class="container-fluid">
    <div class="row" style="margin-left: 1%;">
      <a href="#" [routerLink]="['/dashboard']" style="color:black">Home /</a>
      <a href="#" [routerLink]="['/participant']" style="color:black">Participant Status /</a>
      <a href="#" [routerLink]="['/newparticipant']" class="study">New participant </a>
    </div>
  </div>
  <br>
  <div class="container-fluid">
    <div class="row" style="margin-left: 1%;">
      <div>
        <a href="#" [routerLink]="['/participant']" style="color:black">Participant Status </a>

        <!-- <p class="list">Participant List</p> -->
      </div>
      <div class="addnew">
        <h6 class="new">+ADD New Participant </h6>
      </div>
    </div>
  </div>
  
  <br>
  <div id="tabs">
    <mat-tab-group [selectedIndex]="selectedIndex" dynamicHeight>
      <mat-tab *ngFor="let number of [0];let i=index;" >
        <ng-template mat-tab-label>
          <div class="my-custom-class1">
            <P class="mat-tab-label"> Inclusion & ExclusionCreteria</P>
          </div>
        </ng-template>
        <div class="tab-content">
          <div id="home" class="tab-pane fade in active">
            <div class="container-fluid">
              <div class="row">
                <div class="col-sm-6">
                  <p style="text-align: center;"><u>Inclusion Creteria</u></p>
                  <table class="table table-dark">
                    <thead>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of this.newParticipantRes.inclusionList ">
                        <label class="container" style="margin-left: 11%;">
                          <p class="validity">
                            {{item.validityPoint}}
                          </p>
                          <input value="{{item.check}}" type="checkbox" [(ngModel)]="item.checked"
                            (ngModelChange)="changed(item)">
                          <span class="checkmark"></span>
                        </label>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-sm-5" style="border-left: 1px solid; border-color: black;">
                  <p style="text-align: center;"><u> Exclusion Creteria </u>
                  <p>
                  <table class="table table-dark">
                    <thead>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of newParticipantRes.exclusionList  ">
                        <label class="container">
                          <p
                            style="margin-left: -8px;text-align: justify; max-width: 35%; font-weight: 500;margin-bottom: -9px;">
                            {{item.validityPoint}}
                          </p>
                          <input value="{{item.check}}" type="checkbox" [(ngModel)]="item.checked"
                            (ngModelChange)="changed(item)">
                          <span class="checkmark"></span>
                        </label>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <button class="btn btn-orange text-center" (click)="nextStep()" [disabled]="toggleBool">Continue</button>
          <br>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="my-custom-class2">
            <P class="mat-tab-label1">Registration </P>
          </div>
        </ng-template><br>
        <div class="container-fluid">
          <div class="row">
            <form [formGroup]="Form">
              <div class="col-md-4">
                <mat-form-field appearance="outline">
                  <mat-label>Aadhar Number* </mat-label>
                  <input matInput placeholder="aadharNumber*" formControlName="aadharNumber">
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="outline">
                  <mat-label>First Name* </mat-label>
                  <input matInput type="text" placeholder="First Name*" formControlName="firstname">
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="outline">
                  <mat-label>Last Name*</mat-label>
                  <input matInput type="text" placeholder="Last Name*" formControlName="lastname">
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="outline">
                  <mat-label>Gender*</mat-label>
                  <mat-select (selectionChange)='change($event.value)' formControlName="gender">
                    <mat-option value="" disabled selected>Select Gender</mat-option>
                    <mat-option value="Male">Male</mat-option>
                    <mat-option value="Female">Female</mat-option>
                    <mat-option value="Other">Other</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="outline">
                  <mat-label>Age*</mat-label>
                  <input matInput type="number" placeholder="Age*" formControlName="age">
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="outline">
                  <mat-label>Mobile Number*</mat-label>
                  <input matInput type="number" placeholder="MobileNumber*" formControlName="mobileNumber">
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="outline">
                  <mat-label>Email*</mat-label>
                  <input matInput placeholder="Email*" formControlName="email">
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="outline">
                  <mat-label>Location*</mat-label>
                  <input matInput placeholder="Location*" formControlName="location">
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <p>Id Proof Upload</p>
                <input type="file" accept="image/png, image/gif, image/jpeg" (change)="onChange($event)"
                  formControlName="image">
              </div>

              <!-- 
               <div class="col-sm-3">
                <input id="firstname" class="form-control" placeholder="First Name*" formControlName="firstname">
              </div>
              <div class="col-sm-3">
                <input  type="number" id="age" class="form-control" placeholder="Age*" formControlName="age">
              </div>
                <div class="col-sm-3">
                  <input id="lastname" class="form-control" placeholder="Last Name*" formControlName="lastname">
                </div>
                <div class="col-sm-3">
                  <input  type="number"id="mobileNumber" class="form-control" placeholder="Mobile Number*"
                    formControlName="mobileNumber">
                </div>
              
                <div class="col-sm-3">
                  <input  id="email" class="form-control" placeholder="Email*" formControlName="email">
                </div>
                <div class="col-sm-3">
                  <input   type="number" id="aadharNumber" class="form-control" placeholder="aadharNumber*"
                    formControlName="aadharNumber">
                </div>
              
         
                <div class="col-sm-3">
                  <select   class="form-control" (change)='change($event.target.value)'>
                    <option selected>Select Gender*</option>
                    <option >Male</option>
                    <option  >Female</option>
                    <option >Other</option>
                  </select>
                  
                </div>
                <div class="col-sm-3">
                  <p>Id Proof Upload</p>
                  <input type="file" accept="image/png, image/gif, image/jpeg" (change)="onChange($event)" formControlName="image">
            </div>
                <div class="col-sm-3">
                  <input id="location" class="form-control" placeholder="Location*" formControlName="location">
             
          
          </div>   -->
            </form>
          </div>
        </div>

        <button class="btn btn-orange text-center" (click)="nextStep()" [disabled]="Form.invalid">Continue</button>
        <!-- </form> -->
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="my-custom-class3" (click)="open(content)">
            <P class="mat-tab-label1"> e-Consent </P>
          </div>
        </ng-template>
        <div >
        <div class="row">
          <div class="col-sm-5">
            <pdf-viewer [src]="pdfSource" [original-size]="false">

            </pdf-viewer>

          </div>
          <br>
          <div class="col-sm-6">
            <label> Upload ICF Confirmation</label>
            <input type="file" accept="image/png, image/gif, image/jpeg" (change)="handleFileSelect($event)">
            <button class="btn btn-orange text-center"style="background-color:orange"  (click)="submitform()">SUBMIT</button>

          </div>
          </div>
          </div>
          <!-- <div Class="container-fluid">
            <div class="row">
            <div class="col-sm-5" style="margin-top:15px;margin-left:1%;">
              <select class="form-select" class="selects" (change)='signmethod($event.target.value)'>
                <option selected>Consent Type*</option>
                <option>E-SIGNATURE</option>
                <option> PAPER UPLOAD</option>

              </select>
            </div>

            <div >
              <div class="col-sm-5" style="margin-left:1%;margin-top: 1%;" *ngIf="showLabel">
                <input type="file" accept="image/png, image/gif, image/jpeg" (change)="handleFileSelect($event)">

              </div>
              <div class="col-sm-6" style="border: 1px solid; margin-top: 17px;max-width: 35%;
        background-color: rgba(236, 232, 232, 0.89); margin-left: 2%;" *ngIf="showLabel1">
                <signature-pad [options]="signaturePadOptions" (onBeginEvent)="drawStart()"
                  (onEndEvent)="drawComplete()"></signature-pad><br>
              </div>
              <div class="row" *ngIf="showLabel1">
                <div class="col-sm-3 ">
                  <button type="button" (click)="signaturePad.clear()" class="clear">Clear </button>
                </div>
                <div class="col-sm-3">
                  <button type="button" (click)=" save()" class="apply">Apply </button>
                </div>

              </div><br>
              <button class="btn btn-orange text-center"style="background-color:orange"  (click)="submitform()">SUBMIT</button>
            </div>
          </div>
          </div>
        </div> -->
      </mat-tab>
    </mat-tab-group>
    <br>
    <br>
  </div>
  
</body>

</html>