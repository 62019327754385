import { Component, OnInit } from '@angular/core';

import { ApiService } from '../api.service';
import { Router } from "@angular/router";
@Component({
  selector: 'app-selectedlist',
  templateUrl: './selectedlist.component.html',
  styleUrls: ['./selectedlist.component.css']
})
export class SelectedlistComponent implements OnInit {
  participantRess: any = [];
  searchText: any;
  patientenrolleddate: Number;
  patientId: any;
  constructor(private apiSevice: ApiService, private route: Router) { }

  ngOnInit() {
    let paticipantdata = JSON.parse(localStorage.getItem("paticipantlength") || '{}');
    this.participantRess = paticipantdata;
  }
  //Id click after Id display details in Crf view
  getdetailsId(e: any) {
    console.log(e)
    console.log(e.status)
    if (e.status == 'ACTIVE') {
      this.route.navigate(['Medicationdetails']);
    } else if(e.status =='DROP_OUT'){
      alert("You Can't access this Patient (Dropped).")
    }else if(e.status =='IN-PROGRESS'|| e.status =='OPEN'){
      alert("You Can't access this Patient (Not Active).")
    }
    this.patientenrolleddate = e.enrolledDate;
    console.log(this.patientenrolleddate);
    localStorage.setItem('enrolleddate', JSON.stringify(this.patientenrolleddate));
    this.patientId = e.patientId
    console.log(this.patientId)
    localStorage.setItem('datass', JSON.stringify(this.patientId));
  }
}
