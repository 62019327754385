import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { SignaturePad } from 'angular2-signaturepad';
import { MatTabChangeEvent } from '@angular/material/tabs';

import { Router } from "@angular/router";
import { MatTabGroup } from "@angular/material";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { reduce } from "rxjs-compat/operator/reduce";
import { NewParticipantModelReq } from '../newparticipantreq';
import { ApiService } from '../api.service';
import { NewParticipantRes } from '../newparticipantres';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ParticipantRegistrationResponse } from '../participantregistrationresponse';
import { Observable, Subscriber } from "rxjs";



@Component({
  selector: 'app-newparticipant',
  templateUrl: './newparticipant.component.html',
  styleUrls: ['./newparticipant.component.css']
})
export class NewparticipantComponent implements OnInit {
  participantRegistrationRes = new ParticipantRegistrationResponse()
  newParticiReq = new NewParticipantModelReq()
  newParticipantRes = new NewParticipantRes()
  newParticipantres1: any;
  newParticipantres2: any;
  toggleBool: boolean = true;
  count: number = 0;
  Form: FormGroup;
  base64textString: any
  base64result: any
  signResult: any
selectedgender:any;
showLabel: any;
showLabel1: any;
showLabel2: any;
signimage:any;

  constructor(private modalService: NgbModal, private apiSevice: ApiService, private route: Router, private formBuilder: FormBuilder,) {
  }
  ngOnInit(): void {
    this.Form = this.formBuilder.group({
      firstname: [null, Validators.required],
      age: [null, Validators.required],
      lastname: [null, Validators.required],
      mobileNumber: [null, Validators.required],
      email: [null, Validators.required],
      gender: [null, Validators.required],

      location: [null, Validators.required],
      aadharNumber: [null, Validators.required],
      image: [null, Validators.required]
    });
    let logiResoutputdata2 = JSON.parse(localStorage.getItem("datas") || '{}');
    this.newParticiReq.userId = logiResoutputdata2.result.userId;
    let studyId = JSON.parse(localStorage.getItem("studyId") || '{}');
    this.newParticiReq.studyId = studyId;
    this.apiSevice.newparticipantmethod(this.newParticiReq)
      .subscribe(newparticipantdata => {
        console.log(newparticipantdata);
        this.newParticipantRes = newparticipantdata.result
        console.log(this.newParticipantRes);
        console.log(this.newParticipantRes.inclusionList);
      })
  }
  //checkboxes method
  changed(item: any) {
    console.log(item.checked)
    if (item.checked == true) {
      this.count = this.count + 1;
      if (this.count == this.newParticipantRes.exclusionList.length + this.newParticipantRes.inclusionList.length) {
        this.toggleBool = false;
      }
    } else {
      this.count = this.count - 1;
      this.toggleBool = true;
      console.log("button is deactive")
    }
  }
  //for new enrolled method
  submitform() {
    console.log(this.participantRegistrationRes.firstName);
    console.log(this.participantRegistrationRes.age);
    console.log(this.participantRegistrationRes.lastName);
    console.log(this.participantRegistrationRes.mobileNumber);
    console.log(this.participantRegistrationRes.emailId);
    console.log(this.participantRegistrationRes.gender);
    console.log(this.participantRegistrationRes.location);
    console.log(this.participantRegistrationRes.icfSignURL)
    console.log(this.participantRegistrationRes.proofImageUrl)
    console.log(this.participantRegistrationRes.studyId);
    console.log(this.participantRegistrationRes.trailSiteId);
    console.log(this.participantRegistrationRes.doctorId);

    console.log("value" + this.Form.value.aadharNumber);
    if(this.signimage !=null){
    let logiResoutputdata2 = JSON.parse(localStorage.getItem("datas") || '{}');
   // this.participantRegistrationRes.doctorId = logiResoutputdata2.result.otherId;
    let studydetails = JSON.parse(localStorage.getItem("studydetails") || '{}');
    this.participantRegistrationRes.studyId = studydetails.studyId;
    this.participantRegistrationRes.trailSiteId = studydetails.trailSiteId;
    this.participantRegistrationRes.proofImageUrl = this.base64result;
    this.participantRegistrationRes.icfSignURL =this.signimage;
    this.participantRegistrationRes.firstName = this.Form.value.firstname;
    this.participantRegistrationRes.age = this.Form.value.age;
    this.participantRegistrationRes.lastName = this.Form.value.lastname;
    this.participantRegistrationRes.mobileNumber = this.Form.value.mobileNumber;
    this.participantRegistrationRes.emailId = this.Form.value.email;
    this.participantRegistrationRes.gender = this.selectedgender;
    this.participantRegistrationRes.location = this.Form.value.location;
    this.participantRegistrationRes.aadharNumber = this.Form.value.aadharNumber;
    this.participantRegistrationRes.icfSignType = "E-SIGNATURE";
    this.participantRegistrationRes.enrolledUserId = "1";
    let paticipantlengthdata = JSON.parse(localStorage.getItem("paticipantlength") || '{}');
    let studydata = JSON.parse(localStorage.getItem("studydetails") || '{}');
    let logiResoutput = JSON.parse(localStorage.getItem("datas") || '{}');
    console.log(paticipantlengthdata.length)
    this.participantRegistrationRes.patientId =
      studydata.protocolNumber + "/" + studydetails.trailSiteId + "/" + logiResoutputdata2.result.userId + "/" + (paticipantlengthdata.length + 1)
    console.log(this.participantRegistrationRes.patientId)
    this.apiSevice.EnrolledNewParticipant(this.participantRegistrationRes)
      .subscribe(Enrolleddata => {
        console.log(Enrolleddata)
        //status fail
        if (Enrolleddata.status == 'Fail') {
          alert(Enrolleddata.message)
        }
        else {
          this.route.navigate(['participant']);

        }
      });
    }else{
      alert("fill the form")
    }
  }
  //gender selctor
  change(value ) {
 this.selectedgender=value
  console.log(this.selectedgender)
   }
   //signature type
   signmethod(value){
     console.log(value)
     if ( value=='PAPER UPLOAD') {
      this.showLabel = true;
    }else{
      this.showLabel = false;
    }
     if ( value=='E-SIGNATURE'){
      this.showLabel1=true;
    }else{
      this.showLabel1 = false;
    }
   }
  //observable id proof image
  onChange(evt: any) {
    const file = evt.target.files[0];
    this.convertToBase64(file);
  }
  convertToBase64(file: File) {
    const observable = new Observable((subscriber: Subscriber<any>) => {
      this.readFile(file, subscriber);
    });
    observable.subscribe((d) => {
      this.base64textString = d;
      this.base64result = this.base64textString.split(',')[1];
      console.log(this.base64result);

    });
  }
  readFile(file: File, subscriber: Subscriber<any>) {
    const filereader = new FileReader();
    filereader.readAsDataURL(file);
    filereader.onload = () => {
      subscriber.next(filereader.result);
      subscriber.complete();
    };
    filereader.onerror = (error) => {
      subscriber.error(error);
      subscriber.complete();
    }
  }
  //signature image
  handleFileSelect(evt){
    var files = evt.target.files;
    var file = files[0];

  if (files && file) {
      var reader = new FileReader();

      reader.onload =this._handleReaderLoaded.bind(this);

      reader.readAsBinaryString(file);
  }
}



_handleReaderLoaded(readerEvt) {
   var binaryString = readerEvt.target.result;
          this.signimage= btoa(binaryString);
          console.log("hi"+btoa(binaryString));
          console.log(this.signimage)
  }



  //pdf open method
  open(content: any) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })

  }
  selectedIndex: number = 0;
  maxNumberOfTabs: any;
  public signature: any = null;
  nextStep() {
    if (this.selectedIndex != this.maxNumberOfTabs) {
      this.selectedIndex = this.selectedIndex + 1;
      if (this.count == this.newParticipantRes.exclusionList.length + this.newParticipantRes.inclusionList.length) {
      }
    }
    console.log(this.selectedIndex);
  }


   pdfSource = "https://trials.cliniv.in/data/files/icf_esic.pdf ";
  // @ViewChild(SignaturePad) signaturePad: SignaturePad;
  // signaturePadOptions: Object = {
  //   'minWidth': 5,
  //   'canvasWidth': 400,
  //   'canvasHeight': 150,
  // };
  // ngAfterViewInit() {
  //   this.signaturePad.set('minWidth', 5);
  //    this.signaturePad.clear();
  // }
  // public clear() {
  //   this.signaturePad.clear();
   
       
     
  // }
  // save() {
  //   console.log(this.signResult)
  //   if (this.signResult) {
  //         this.showLabel1 = false;
  //       }

  // }
  // drawComplete() {
  //   this.signature = this.signaturePad.toDataURL('image/png', 0.5);
  //   this.signResult = this.signaturePad.toDataURL().split(',')[1];
  // }
  // drawStart() {
  //   console.log('begin drawing');
  // }
  
}

