import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ApiService } from '../api.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AddLabReportsModel } from '../addlabreportsmodel';
import { Observable, Subscriber } from "rxjs";
@Component({
  selector: 'app-newlabreport',
  templateUrl: './newlabreport.component.html',
  styleUrls: ['./newlabreport.component.css']
})
export class NewlabreportComponent implements OnInit {

  addLabReportsModel = new AddLabReportsModel();
  Form: FormGroup;
  base64textString: any
  base64result: any
  constructor(private apiSevice: ApiService, private route: Router, private formBuilder: FormBuilder,) {
  }

  ngOnInit() {
    let logiResoutputdata2 = JSON.parse(localStorage.getItem("datas") || '{}');
    console.log(logiResoutputdata2)
    this.Form = this.formBuilder.group({
      testname: [null, Validators.required],
      testvalue: [null, Validators.required],
      image: [null, Validators.required]
    })
    console.log(this.Form.value.testname)

  }
  onChange(evt: any) {
    const file = evt.target.files[0];
    this.convertToBase64(file);

  }
  convertToBase64(file: File) {
    const observable = new Observable((subscriber: Subscriber<any>) => {
      this.readFile(file, subscriber);
    });
    observable.subscribe((d) => {
      this.base64textString = d;
      this.base64result = this.base64textString.split(',')[1];
      console.log(this.base64result);

    });
  }
  readFile(file: File, subscriber: Subscriber<any>) {
    const filereader = new FileReader();
    filereader.readAsDataURL(file);
    filereader.onload = () => {
      subscriber.next(filereader.result);
      subscriber.complete();
    };
    filereader.onerror = (error) => {
      subscriber.error(error);
      subscriber.complete();
    }
  }


  onSubmit() {
    console.log(this.Form.value.testname)

    console.log(this.Form.value.testname)
    if(this.Form.valid  && this.base64result!=null){
    let logiResoutputdata2 = JSON.parse(localStorage.getItem("datas") || '{}');
    this.addLabReportsModel.userId = logiResoutputdata2.result.userId;
    console.log(logiResoutputdata2.result.userId)
    console.log(this.addLabReportsModel.userId);
    console.log(this.addLabReportsModel.studyId)
    console.log(this.addLabReportsModel.patientId)
    console.log(this.addLabReportsModel.fileURL)
    console.log(this.addLabReportsModel.testName)
    console.log(this.addLabReportsModel.testValue)
    let studyId = JSON.parse(localStorage.getItem("studyId") || '{}');
    this.addLabReportsModel.studyId = studyId;
    let patientId = JSON.parse(localStorage.getItem("datass") || '{}');
    this.addLabReportsModel.patientId = patientId;
    this.addLabReportsModel.testName = this.Form.value.testname;
    this.addLabReportsModel.testValue = this.Form.value.testvalue;
    this.addLabReportsModel.fileURL = this.base64result;
    this.apiSevice.addlabreportsmethod(this.addLabReportsModel)
      .subscribe(labreportsdata => {
        console.log(labreportsdata)
        this.route.navigate(['Labreports']);
      });
    }else{
      alert("Please fill all the Mandatory Fields")
    }

  }
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('datas');
    this.route.navigate(['login']);
  }
}
