import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdverseModel } from '../adversemodel';
import { ApiService } from '../api.service';
import { AdverseModelReasponse } from '../adversemodelrespone';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AdverseModelRequest } from '../adversemodelrequest';
@Component({
  selector: 'app-updatemedicine',
  templateUrl: './updatemedicine.component.html',
  styleUrls: ['./updatemedicine.component.css']
})
export class UpdatemedicineComponent implements OnInit {

  patientId: any
  madecinedate: any
  adverseModel = new AdverseModel();
  AdverseModelReasponse: AdverseModelReasponse[];
  selectedItemsList = [];
  adaverse: any;
  Form: FormGroup;
  symptoms: any;
  adverseModelReq = new AdverseModelRequest();
  enrolledday: any;
  presentday: any;
  differencedate: any;
  date: Date;
  symptom: any = [];
  showLabel: any;
  Form1:any;
  Form2:any;
  constructor(private route: Router, private modalService: NgbModal, private apiService: ApiService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.Form = this.formBuilder.group({
      // VitaminDselected: ["", Validators.required],
      Respiratory: ["", Validators.required],
      vitaminK2selected: ["", Validators.required],
      vitamink2adverse: ["", Validators.required],
      Magnesiumselected: ["", Validators.required],
      Magnesiumadverse: ["", Validators.required],
      bloodPressure: ["", Validators.required],
      //diastolic: ["", Validators.required]
    });
    //for form show 
    let logiResoutputdata = JSON.parse(sessionStorage.getItem("logindatas") || '{}');
    let UpdateMediForm = JSON.parse(sessionStorage.getItem("UpdateMediForm") || '{}');
     console.log(UpdateMediForm)
    if (UpdateMediForm=="ViewMedication") {
      this.Form1=true;
    }else{
      this.Form1=false;
    }
    if(UpdateMediForm=="UpdateMedication"){
      this.Form2=true;
    }else{
      this.Form2=false;
    }
    let patientId = JSON.parse(localStorage.getItem("datass") || '{}');
    this.patientId = patientId;
    let medicinedate = JSON.parse(localStorage.getItem("datamadecine") || '{}');
    this.madecinedate = medicinedate;
    //enrolled date
    let enrolldate = JSON.parse(localStorage.getItem("enrolleddate") || '{}');
    let medicinedata = JSON.parse(localStorage.getItem("datamadecine") || '{}');
    //diffrence between days different month
    let date1 = new Date(enrolldate);
    let date2 = new Date(medicinedata);
    let Time = date2.getTime() - date1.getTime();
    let Days = Time / (1000 * 3600 * 24);
    this.differencedate = Math.floor(Days);
    console.log(this.differencedate)
    if (this.differencedate == 1 ||this.differencedate == 8 || this.differencedate == 15 || this.differencedate == 22 || this.differencedate == 29
      || this.differencedate == 36 || this.differencedate == 43) {
      this.showLabel = true;
    }else{
      this.showLabel = false;
    }
    //already user update to check the details
    let upadtedata = JSON.parse(localStorage.getItem("participantdairydata") || ' {}');
    console.log(upadtedata.result.length)
    console.log(upadtedata)
      if(upadtedata.result.length >0){
    this.Form.controls["Respiratory"].setValue(upadtedata.result[0].spo2);
    this.Form.controls["vitaminK2selected"].setValue(upadtedata.result[0].vitaminK2Taken);
    this.Form.controls["vitamink2adverse"].setValue(upadtedata.result[0].vitaminK2SE);
    this.Form.controls["Magnesiumselected"].setValue(upadtedata.result[0].vitaminDTaken);
    this.Form.controls["Magnesiumadverse"].setValue(upadtedata.result[0].vitaminDSE);
    this.Form.controls["bloodPressure"].setValue(upadtedata.result[0].bloodPressure);
    this.symptom=upadtedata.result[0].adverseEvents;
      }
  }
  // model open dat select adverseve events api titles of sympotoms 
  open(content: any) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
    let patientId = JSON.parse(localStorage.getItem("datass") || '{}');
    this.adverseModel.patientId = patientId;
    let logiResoutputdata2 = JSON.parse(localStorage.getItem("datas") || '{}');
    this.adverseModel.userId = logiResoutputdata2.result.userId;
    this.apiService.adversemethod(this.adverseModel)
      .subscribe(adversedata => {
        console.log(adversedata)
        this.AdverseModelReasponse = adversedata.result;
        this.adaverse = this.AdverseModelReasponse;
      })
  }
  //checked value display UI
  changeSelection(value) {
    console.log(value.title)
    this.symptoms = value.title;
    this.selectedItemsList = this.adaverse.filter((value: any) => {
      return value.isChecked
    });

  }
  symtomMethod(){
  let sym:any=[];
    for (let i = 0; i < this.selectedItemsList.length; i++) {
      let selectedlist= this.selectedItemsList[i]
      console.log(selectedlist)
      sym.push(selectedlist)
    }
    console.log(sym)
    let arra: any = []
    for (let item of sym) {
      arra.push(item.title)
    }
    console.log(arra)
    this.symptom = arra.toString();
    console.log(this.symptom)
  } 
  //update the medication details
  Medicationdata() {
    console.log(this.symptom)
    console.log(this.Form.value.vitaminK2selected)
    let studydetailsdata = JSON.parse(localStorage.getItem("studydetails") || '{}');
    let patientId = JSON.parse(localStorage.getItem("datass") || '{}');
    let logiResoutputdata2 = JSON.parse(localStorage.getItem("datas") || '{}');
    this.adverseModelReq.studyId = studydetailsdata.studyId;
    this.adverseModelReq.patientId = patientId;
   // this.adverseModelReq.userId = logiResoutputdata2.result.userId;
    this.adverseModelReq.dayNumber = this.differencedate;
    this.adverseModelReq.spo2 = this.Form.value.Respiratory;
    this.adverseModelReq.vitaminK2SE = this.Form.value.vitamink2adverse;
    this.adverseModelReq.vitaminDSE = this.Form.value.Magnesiumadverse;
    // this.adverseModelReq.vitaminDTaken = this.Form.value.VitaminDselected;
    this.adverseModelReq.vitaminK2Taken = this.Form.value.vitaminK2selected;
    this.adverseModelReq.vitaminDTaken = this.Form.value.Magnesiumselected;
    this.adverseModelReq.bloodPressure=this.Form.value.bloodPressure;
    //this.adverseModelReq.createdDate = ""
    this.adverseModelReq.remarks = logiResoutputdata2.result.userId;
     this.adverseModelReq.adverseEvents =this.symptom;
     console.log(this.adverseModelReq)
    this.apiService.upadatemethod(this.adverseModelReq)
      .subscribe(updatedata => {
        console.log(updatedata)
        this.route.navigate(['ParticipantDairy']);
      })
  }


}
