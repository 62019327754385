<!DOCTYPE html>
<html>

<head>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
  <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
  
</head>

<body>
  <app-header></app-header>
 <br>
 <div class="container-fluid ">
   <div class="row ml-2">
     <a href="#" [routerLink]="['/dashboard']" class="home">Home /</a>
     <a href="#" [routerLink]="['/studydocuments']" class="study">Study Documents</a>

   </div>
 </div>
 <br>

  <div class="container-fluid bg-3 text-center">
    <div class="row ml-2">   
        <div class="col-md-3 mb-2" *ngFor="let studyDocumentModels of studyDocumentModelArray"style="cursor: pointer;">

          <div class="pdf-box" (click)="open(content, studyDocumentModels)">
            <img [src]="myimage" alt="" class="docpdf">
            <p class="titleheight"><b>{{studyDocumentModels.title}}</b></p>
         
        </div>
      </div>
    </div>
  </div>
  <ng-template #content let-modal>
    <div class="modal-header" style="background-color: orange;">
      <div class="titlecolor">
        {{studyDocumentModels.title}}
      </div>
      <p (click)="modal.dismiss('Cross click')" style="cursor: pointer;color:white;
      font-size: 12px; border: 1px solid;padding: 2px 16px;">CLOSE </p>
    </div>
    <div class="modal-body">
      <pdf-viewer src="{{studyDocumentModels.fileUrl}}" [rotation]="0" [fit-to-page]="true" [original-size]="true"
        [show-all]="true" style="display: block;"></pdf-viewer>
    </div>
  </ng-template>
</body>

</html>