import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Router } from "@angular/router";
import { AdverseModel } from '../adversemodel';
import { LabReportsModel } from '../labreportsmodel';
import { ApproveModel } from '../approvemodel';
@Component({
  selector: 'app-approvepatient',
  templateUrl: './approvepatient.component.html',
  styleUrls: ['./approvepatient.component.css']
})
export class ApprovepatientComponent implements OnInit {

  approveModel = new ApproveModel();
  adverseModel = new AdverseModel();
  enrolleddate: any
  status: any;
  labReportsModel = new LabReportsModel();
  isshow: any;
  testvalue: any;

  constructor(private apiService: ApiService, private route: Router) { }

  ngOnInit() {
    let logiResoutputdata2 = JSON.parse(localStorage.getItem("datas") || '{}');
    console.log(logiResoutputdata2)
    if (logiResoutputdata2.result.roleId == '5') {
      this.isshow = true;
    }
    this.adverseModel.userId = logiResoutputdata2.result.userId;
    let patientfulldata = JSON.parse(localStorage.getItem("singlepatientfulldata") || '{}');
    console.log(patientfulldata)
    this.adverseModel.patientId = patientfulldata.patientId;
    this.enrolleddate = patientfulldata.enrolledDate
    this.status = patientfulldata.status;
    this.apiService.inprogresssmethod(this.adverseModel)
      .subscribe(inprogressdata => {
        this.labReportsModel.testName = inprogressdata.result[0].testName;
        this.labReportsModel.testValue = inprogressdata.result[0].testValue;
        this.labReportsModel.fileURL = inprogressdata.result[0].fileURL;
        this.testvalue = inprogressdata.result[0].testValue;

      })

  }
  Submit() {
    let logiResoutputdata2 = JSON.parse(localStorage.getItem("datas") || '{}');
    this.approveModel.userId = logiResoutputdata2.result.userId
    let studyId = JSON.parse(localStorage.getItem("studyId") || '{}');
    this.approveModel.studyId = studyId;
    let data2 = JSON.parse(localStorage.getItem("singlepatientfulldata") || '{}');
    this.approveModel.patientId = data2.patientId;
    this.approveModel.mobileNumber = data2.mobileNumber;
    this.approveModel.testValue = this.testvalue;
    this.apiService.approvepatientmethod(this.approveModel)
      .subscribe(approveddata => {
        console.log(approveddata)
        this.route.navigate(['participant'])

      })
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('datas');
    this.route.navigate(['login']);
  }
}
