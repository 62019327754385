<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>

  <style>
  </style>
</head>

<body>
 <app-header></app-header>
  <br>
  <div class="container-fluid">
    <div class="row ml-1">
      <a href="#" [routerLink]="['/dashboard']" style="color:black">Home /</a>
      <a href="#" [routerLink]="['/participant']" style="color:black">Participant Status /</a>
      <a href="#" [routerLink]="['/Patientscreening']" class="study">Participant Screening </a>

    </div>
  </div>
  <br>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <form [formGroup]="Form" (ngSubmit)="onSubmit()">
          <mat-form-field appearance="outline">
            <mat-label>Vitamin D Value</mat-label>
            <input matInput placeholder="Vitamin D Value*" formControlName="testvalue" required>
          </mat-form-field>
          <div>
            <label>Add Report</label>
            <input type="file" (change)="onChange($event)" formControlName="image">
          </div>
          <button class="btn btn-orange text-center" >SUBMIT</button>
        </form>
      </div>
    </div>
  </div>
</body>


</html>