export class SubmitVisitModel{
    
    "studyId":any;
        "questionId":Number;
        "userId":Number;
        "patientId":Number;
        "visitId":Number;
    "doctorId":Number;
    "sectionId":Number;
    "answer":String;
    "remarks":String;
    
}