


      <div class="container-fluid themeclr" >
        <div class="row" style="color:#f0f8ff; padding: 7px;">
          <div class="col-md-4" >
            <h4  [routerLink]="['/myprofile']" style="cursor: pointer;">Profile</h4>
          </div>
          <div class="col-md-4 text-center" style="letter-spacing: 4px; ">
            <h4>{{this.loginName}}</h4>
          </div>
         
          <div class="col-md-3" style="text-align: right;">
            <!-- <h4 id="help" style="cursor: pointer;" (click)="helpmethod()" style="cursor: pointer;"
         >Help</h4> -->
          
          </div>
          <div class="col-md-1" style="text-align: right;">
            <h4  style="cursor: pointer;" (click)="logout()" style="cursor: pointer;"
              >Logout</h4>
          </div>
        </div>
      </div>
