<!DOCTYPE html>
<html lang="en">

<head>
  <title>Bootstrap Example</title>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
  
</head>

<body>

  <app-header></app-header>
  <br>
  <div class="container-fluid" >
    <div class="row ml-1">
        <a href="#" [routerLink]="['/dashboard']" style="color:black">Home /</a>
        <a href="#" [routerLink]="['/participant']" style="color:black;">Participant Status </a>
        <a href="#" [routerLink]="['/CRFView']" style="color:black;">/ CRF View</a>
        <a href="#" [routerLink]="['/CRFDetails']" class="study" >/ Visit-Basic Details</a>
    </div>
  </div>
  <br>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-3">
        <P>Participant Id : {{ this.crfDetailsModel.patientId}}</P>
      </div>
    </div>
  </div>
<br>
    <!-- <div class="container-fluid">
    <div class="row">
      <div >
  <form id="myForm" [formGroup]="thisIsMyForm">
    <div [formArrayName]="'formArrayName'">
      <div class="col-sm-12" *ngFor="let x of this.section1; let i = index">
      <div [formGroupName]="i">
      <mat-form-field appearance="outline" >
        <mat-label>{{x.questionTitle}} </mat-label>
        <input matInput type="NUMBER"
        *ngIf='x.questionType !== "SELECT" &&  x.questionType !== "SELECT-MULTI"  && x.questionType !== "DATE" && x.questionType !== "TEXT"'
        formControlName="answer" value="{{x.answer}}" placeholder=" {{x.questionComments}}"
           [required]="x.required">
           <input matInput type="TEXT"
           *ngIf='x.questionType !== "SELECT" &&  x.questionType !== "SELECT-MULTI"  && x.questionType !== "DATE" && x.questionType !== "NUMBER"'
           formControlName="answer" value="{{x.answer}}" placeholder=" {{x.questionComments}}"
           [required]="x.required">
        <mat-select *ngIf='x.questionType == "SELECT"' value="{{x.answer}}" formControlName="answer"
          [required]="x.required">
          <mat-option value="" disabled selected>Select Option</mat-option>
          <mat-option *ngFor="let opt of x.options?.split(',')" value={{opt}}>{{opt}}</mat-option>
        </mat-select>
        </mat-form-field>
        </div>
        </div> -->
      <!-- <fieldset  class="scheduler-border" *ngFor="let x of this.section1; let i = index">
        <div [formGroupName]="i">
          <legend class="scheduler-border">{{x.questionTitle}}</legend>
        <input [type]="x.questionType" *ngIf='x.questionType !== "Select"'formControlName="answer" class="form-control"value="{{x.answer}}"placeholder="Enter the {{x.questionType}}">
        <select class="form-control" *ngIf='x.questionType == "Select"' value="{{x.answer}}"formControlName="answer">
          <option selected>Select option*</option>
          <option *ngFor="let opt of x.options?.split(',')">{{opt}}</option>
        </select>
       </div>
    </fieldset>
    
    <fieldset  class="scheduler-border" *ngFor="let x of this.section2; let i = index">
      <div [formGroupName]="i">
       
        <legend class="scheduler-border">{{x.questionTitle}}</legend>
      <input [type]="x.questionType" *ngIf='x.questionType !== "Select"'formControlName="answer" class="form-control"value="{{x.answer}}"placeholder="Enter the {{x.questionType}}">
      <select  class="form-control" *ngIf='x.questionType == "Select"' value="{{x.answer}}"formControlName="answer">
        <option selected>Select option*</option>
        <option *ngFor="let opt of x.options?.split(',')">{{opt}}</option>
      </select>
     </div>
  </fieldset> -->

    <!-- <button class="btn btn-orange text-center"(click)="equal()">Submit </button>
    </div>
  </form>
</div>
</div>
</div> -->
<form  [formGroup]="thisIsMyForm">

<div class="container-fluid">
    <div [formArrayName]="'formArrayName'">
      <label><b>{{header1}}</b></label>

      <div class="row">
        <div class="col-md-4" *ngFor="let x of this.section1; let i = index">
          <div [formGroupName]="i">
            <mat-form-field appearance="outline">
              <mat-label>{{x.questionTitle}} </mat-label>
             <input matInput type="Number"
                *ngIf='x.questionType !== "Select" && x.questionType !== "Text"' formControlName="answer"
                value="{{x.answer}}" placeholder=" {{x.questionComments}}"    [readonly]="!editible">   
                <input matInput type="Text"
                *ngIf='x.questionType !== "Select" && x.questionType !== "Number"' formControlName="answer"
                value="{{x.answer}}" placeholder=" {{x.questionComments}}"    [readonly]="!editible">   

              <mat-select  *ngIf='x.questionType == "Select"'  value="{{x.answer}}" formControlName="answer"
               >
                <mat-option *ngFor="let opt of x.options?.split(',')" value={{opt}}  [disabled]="!editible">{{opt}}
                </mat-option>
              </mat-select>
            </mat-form-field>

          </div>
          
        </div>
        </div>
        <br>
        <label ><b>{{header2}}</b></label>
</div>
<div [formArrayName]="'formArrayName1'">

        <div class="row">

        <div class="col-md-4" *ngFor="let x of this.section2; let i = index">
          <div [formGroupName]="i">
            <mat-form-field appearance="outline">
              <mat-label>{{x.questionTitle}} </mat-label>
              <input matInput type="Number"
                *ngIf='x.questionType !== "Select" && x.questionType !== "Text"' formControlName="answer"
                value="{{x.answer}}" placeholder=" {{x.questionComments}}"   [readonly]="!editible">      
                <input matInput type="Text"
                *ngIf='x.questionType !== "Select" && x.questionType !== "Number"' formControlName="answer"
                value="{{x.answer}}" placeholder=" {{x.questionComments}}"   [readonly]="!editible">              
              <mat-select *ngIf='x.questionType == "Select"' value="{{x.answer}}" formControlName="answer"
               >
                <mat-option value="" disabled selected>Select Option</mat-option>
                <mat-option *ngFor="let opt of x.options?.split(',')" value={{opt}} [disabled]="!editible" >{{opt}}
                </mat-option>
              </mat-select>
            </mat-form-field>

          </div>
          
        </div> 
      </div>
    </div>
    <button class="btn btn-orange text-center"(click)="equal()" *ngIf="editible">Submit </button>
</div>
</form> 
<br>

<div class="container-fluid" *ngIf="showLabel">
  <div clas="row">
    <mat-form-field appearance="outline">
      <mat-label>Remarks</mat-label>
      <input matInput  placeholder="Remarks*" (keyup)="inputtext($event)">
    </mat-form-field>
    <button class="btn btn-orange text-center"(click)="Submit()" >APPROVE VISIT </button>
  </div>
</div>
<br>
<br>
<br>
</body>

</html>