import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Router } from "@angular/router";
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
userId:any;
loginNames:any;
loginPicture:any;
logindisplayName:any;
firstName:any;
lastName:any;
fullName:any;
gender:any;
MedicalCouncil:any;
mobileNumber:any;
emailId:any;
registration:any;
location:any;
dob:any;
locations:any
  constructor(private apiService: ApiService, private route: Router) { }

  ngOnInit() {
    let logiResoutputdata = JSON.parse(localStorage.getItem("datas")|| '{}');
    this.loginPicture = logiResoutputdata.result.displayPictureUrl;
    this.logindisplayName=logiResoutputdata.result.displayName;
     //which user is login
     if (logiResoutputdata.result.roleId == '5') {
      this.loginNames = "INVESTIGATOR";
    }
    else if (logiResoutputdata.result.roleId == '14') {
      this.loginNames = "CO-INVESTIGATOR";
    }
    else if (logiResoutputdata.result.roleId == '13') {
      this.loginNames = "CO-ORDINATOR";
    }
     this.userId=logiResoutputdata.result.userId;

    this.apiService.myprofilemethod(this.userId)
    .subscribe(myprofiledata => {
       console.log(myprofiledata);       
         this.firstName = myprofiledata.result.user.firstName;
           this.lastName = myprofiledata.result.user.lastName;
          this.dob  =myprofiledata.result.user.dob;
          this.emailId=myprofiledata.result.user.emailId;
          this.MedicalCouncil=myprofiledata.result.medicalCouncil;
          this.registration=myprofiledata.result.regYear;
           this.gender =myprofiledata.result.user.gender;
           this.mobileNumber=myprofiledata.result.mobile;
           this.locations=myprofiledata.result.locations[0].title +","+ myprofiledata.result.locations[0].locality  +","+ 
           myprofiledata.result.locations[0].city;
    })
  }



}
