import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PinComponent } from './pin/pin.component';
import {LoginComponent } from './login/login.component';
import {DashboardComponent } from './dashboard/dashboard.component';
import { StudyDocumentsComponent } from './study-documents/study-documents.component';
import{ParticipantComponent} from'./participant/participant.component';
import{NewparticipantComponent} from'./newparticipant/newparticipant.component';
import { CRFComponent } from './crf/crf.component';
import { CRFDetailsComponent } from './crfdetails/crfdetails.component';
import { ParticipantdairyComponent } from './participantdairy/participantdairy.component';
import { UpdatemedicineComponent } from './updatemedicine/updatemedicine.component';
import { SelectedlistComponent } from './selectedlist/selectedlist.component';
import { MedicationdetailsComponent } from './medicationdetails/medicationdetails.component';
import { LabreportsComponent } from './labreports/labreports.component';
import { NewlabreportComponent } from './newlabreport/newlabreport.component';
import { PatientscreeningComponent } from './patientscreening/patientscreening.component';
import { ApprovepatientComponent } from './approvepatient/approvepatient.component';
import { AuthenticationGuard } from './authentication.guard';
import { ProfileComponent } from './profile/profile.component';
const routes: Routes = [
  
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    { path: "login" ,component: LoginComponent },
    { path: "pin" ,component: PinComponent },
    { path:"myprofile",component:ProfileComponent,canActivate:[AuthenticationGuard]},
    { path:"dashboard",component:DashboardComponent,canActivate:[AuthenticationGuard]},
    { path:"studydocuments",component:StudyDocumentsComponent,canActivate:[AuthenticationGuard]},
    {path:"participant",component:ParticipantComponent,canActivate:[AuthenticationGuard]},
    { path:"newparticipant",component:NewparticipantComponent,canActivate:[AuthenticationGuard]},
    { path:"CRFView",component:CRFComponent,canActivate:[AuthenticationGuard]},
    { path:"CRFDetails",component:CRFDetailsComponent,canActivate:[AuthenticationGuard]},
    {path:"ParticipantDairy",component:ParticipantdairyComponent,canActivate:[AuthenticationGuard]},
    {path:"updatemedicine",component:UpdatemedicineComponent,canActivate:[AuthenticationGuard]},
    {path:"selectedlist",component:SelectedlistComponent,canActivate:[AuthenticationGuard]},
    {path:"Medicationdetails",component:MedicationdetailsComponent,canActivate:[AuthenticationGuard]},
    {path:"Labreports",component:LabreportsComponent,canActivate:[AuthenticationGuard]},
    {path:"NewLabreports",component:NewlabreportComponent,canActivate:[AuthenticationGuard]},
    {path:"Patientscreening",component:PatientscreeningComponent,canActivate:[AuthenticationGuard]},
    {path:"Approvepatient",component:ApprovepatientComponent,canActivate:[AuthenticationGuard]}
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
