<!DOCTYPE html>
<html lang="en">

<head>
  <title>Bootstrap Example</title>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
  <style>
  </style>
</head>

<body>
  <app-header></app-header>
  <br>

  <div class="container-fluid">
    <div class="row ml-2 ">
      <a href="#" [routerLink]="['/dashboard']" style="color:black">Home /</a>
      <a href="#" [routerLink]="['/participant']" style="color:black"> Participant Status </a>
      <a href="#" [routerLink]="['/Approvepatient']" class="study"> / Approve Participant </a>
    </div>
  </div>
  <br>
 
  <div class="container-fluid">
    <div> 
      <button (click)="Submit()" class="btn btn-orange text-center" *ngIf="isshow">APPROVE PARTICIPANT</button>
      </div>
      <br>
      <div class="col-md-9">
        <div >
          <p>Participant Id:<b>{{this.adverseModel.patientId}}</b></p>
          <p>Enrolled Date Time: <b>{{this.enrolleddate}}</b></p>
          <p>Status:<b>{{ this.status}}</b></p>
          <P>Lab Test Name:<b>{{ this.labReportsModel.testName}}</b></P>
          <p>Lab Test Value:<b>{{ this.labReportsModel.testValue}}</b></p>
        </div>
      </div>
      <br>
      <P style="margin:2px;">Test Report</P>

      <img src="{{ this.labReportsModel.fileURL}} ">
    
  </div>
  <br>
</body>


</html>