export class LabReportsModel{
    "reportId": Number;
    "dayNumber": Number;
    "studyId": Number;
    "userId": Number;
    "mobileNumber": Number;
    "patientId": String;
    "testName": String;
    "fileURL": string;
    "testValue": Number;
    "createdDate":Number;
}