export class StudyModelResponse {
    "studyId": Number;
    "companyId": Number;
    "studyName":String;
    "companyName": String;
    "startDate": Number;
    "endDate": Number;
    "createdDate": Number;
    "therapeuticIds": Number;
    "therapeuticNames": String;
    "studyShortName": String;
    "studyBannerURL": String;
    "protocolNumber": Number;
    "principalInvestigator": String;
    "totalVisits": Number;
    "userStatus": String;
    "patientDuration": Number;
    "contactName":String;
    "contactMobile":Number;
    "contactEmail": String;
    "docAggreementUrl":String;
    "trailSiteName": String;
    "coPrincipalInvestigator": String;
    "trailSites": String;
    "trailSiteId": Number;
    "studyStatus":String
}