export class AdverseModelRequest{
//      "vitaminDSE": String;
//     "vitaminK2SE":String;
//     "magenesiumSE":String;
//     "studyId": Number;
//     "patientId": Number;
//     "userId": Number;
//     "dayNumber": Number;
//     "adverseEvents": String;
//     "magnesiumTaken": String;
//     "vitaminDTaken":String;
//     "vitaminK2Taken":String;
//    "magnesiumTaken":String;




    "studyId":Number;
    "userId":Number;
    "dayNumber":Number;
    "patientId":Number;
    "vitaminDSE":String;
    "vitaminK2SE":String;
    "magenesiumSE":String;
    "adverseEvents":String;
    "vitaminDTaken":String;
     "vitaminK2Taken":String;
    "magnesiumTaken":String;
    "createdDate":String;
    "remarks":String;
    "spo2":any;
    "bloodPressure";
}