
<!DOCTYPE html>
<html lang="en">
<head>

</head>
<body  (contextmenu)="onRightClick()">
  <div *ngIf="isLoading | async"  class="loader-container">
    <mat-spinner style="top:25%;left:50%">
    </mat-spinner>
    </div>
      <router-outlet ></router-outlet>
</body>
<footer class="container-fluid" *ngIf="showHead">
  <!-- <p> <a href="https://www.cliniv.in/" target="_blank"> Cliniv Health Tech </a> 
  </p> -->
  <p>Copyright 2021 <a href="https://www.cliniv.in/" target="_blank"> Cliniv Health Tech </a> All rights are reserved
  </p>

</footer>

</html>


