<!DOCTYPE html>
<html lang="en">

<head>
  <title>Bootstrap Example</title>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
  <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
  <style>
    /* Remove the navbar's default margin-bottom and rounded borders */
  </style>
</head>

<body>
  <app-header></app-header>
  <br>
  <div class="container-fluid">
    <div class="row ml-2">
        <a href="#" [routerLink]="['/dashboard']" style="color:black">Home /</a>
        <a href="#" [routerLink]="['/selectedlist']" style="color:black">Select Participant </a>
        <a href="#" [routerLink]="['/Medicationdetails']" class="study">/ Medication details </a>
  </div>
  </div>
  <br>
  <div class="container-fluid">
    <div class="row ml-1">
        <P>Participant Id : {{this.adverseModel.patientId}}</P>
    </div>
  </div>
  <br>
  <div class="container-fluid">
    <div class="row" *ngFor="let item of this.drugStockModel">
      <div class="col-sm-6" >
          <mat-form-field appearance="outline">
            <mat-label>{{item.productTitle}} </mat-label>
            <input matInput value="{{item.availableStock }}" placeholder="Available Stock">
          </mat-form-field>

        </div>
        <div class="col-sm-6">
          <mat-form-field appearance="outline">
            <mat-label>{{item.productTitle}} </mat-label>
            <input matInput value="{{item.totalStock }}" placeholder="Total Stock">
          </mat-form-field>
        </div>
      </div>
    </div>
</body>

</html>