import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { CrfDetailsModel } from '../Crfdetailsmodel';
import { ApiService } from '../api.service';
import { SubmitVisitModel } from '../submitvisitmodel';
import { Custom } from '../custom';
import { MatSnackBar } from '@angular/material';
import { ApprovePatient } from '../ApprovePatienModel';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
@Component({
  selector: 'app-crfdetails',
  templateUrl: './crfdetails.component.html',
  styleUrls: ['./crfdetails.component.css']
})
export class CRFDetailsComponent implements OnInit {

  crfDetailsModel = new CrfDetailsModel();
  submitVisitModel = new SubmitVisitModel();
  approvePatient = new ApprovePatient();
  section1: [];
  section2: [];
  options: any;
  fillstatus: any;
  sectionId: any;
  doctorstatus: any;
  selectedvalue: any;
  totalquestions: any;
  thisIsMyForm: FormGroup;
  visitName: any;
  header1: any;
  header2: any
  editible: any;
  array: any;
  crfarray: any = [];
  array1: any;
  crfarray1: any = [];
  totalcrfarray: any;
  status: any;
  intext: any;
  showLabel:any;
  
  constructor(private formBuilder: FormBuilder, private route: Router, private apiService: ApiService,) {
    this.thisIsMyForm = new FormGroup({
      formArrayName: this.formBuilder.array([]),
      formArrayName1: this.formBuilder.array([]),

    })
    //console.log(this.thisIsMyForm);
  }
  ngOnInit() {

    let userststus = JSON.parse(localStorage.getItem("userstatus") || '{}');
    this.fillstatus = userststus;
    let status = JSON.parse(localStorage.getItem("doctorstatus") || '{}');
    this.doctorstatus = status;
    let Crfdata = JSON.parse(localStorage.getItem("Crfdata") || '{}');
    this.crfDetailsModel.visitId = Crfdata;
    let studydetailsdata = JSON.parse(localStorage.getItem("studydetails") || '{}');
    this.crfDetailsModel.studyId = studydetailsdata.studyId;
    let data2 = JSON.parse(localStorage.getItem("datass") || '{}');
    this.crfDetailsModel.patientId = data2;
    let logiResoutputdata2 = JSON.parse(localStorage.getItem("datas") || '{}');

    this.crfDetailsModel.userId = logiResoutputdata2.result.userId;
    console.log(this.crfDetailsModel)
    this.apiService.crfVisitmethod(this.crfDetailsModel)
      .subscribe(crfdetailsdata => {
        console.log(crfdetailsdata);
        this.status = crfdetailsdata.result.userStatus
        console.log(this.status)
        if (logiResoutputdata2.result.roleId == '14' && this.status == "OPEN") {
          this.editible = true;
        } else {
          this.editible = false;
        }
        if (logiResoutputdata2.result.roleId == '5' && this.status == "SUBMITTED" && this.doctorstatus=="OPEN") {
          this.showLabel = true;
        } else {
          this.showLabel = false;
        }
        console.log(crfdetailsdata.result.sections[0].sectionId);
        this.sectionId = crfdetailsdata.result.sections[0].sectionId;
        console.log(crfdetailsdata.result.sections[1]);
        this.section1 = crfdetailsdata.result.sections[0].questions;
        console.log(this.section1)
        this.header1 = crfdetailsdata.result.sections[0].title;
        this.header2 = crfdetailsdata.result.sections[1].title;

        this.section2 = crfdetailsdata.result.sections[1].questions;
        this.totalquestions = crfdetailsdata.result.sections[0].questions.length + crfdetailsdata.result.sections[1].questions.length;
        console.log(this.totalquestions)

        this.options = crfdetailsdata.result.sections[0].questions.options;
        this.buildForm();
      });
    let logiResoutputdata3 = JSON.parse(localStorage.getItem("datas") || '{}');
    this.submitVisitModel.userId = logiResoutputdata3.result.userId;
    let data3 = JSON.parse(localStorage.getItem("datass") || '{}');
    this.submitVisitModel.patientId = data3;
    let Crfdata2 = JSON.parse(localStorage.getItem("Crfdata") || '{}');
    this.submitVisitModel.visitId = Crfdata2;
    // let doctorId = JSON.parse(localStorage.getItem("CrfdoctorId") || '{}');
    // console.log(doctorId)
    //this.submitVisitModel.doctorId = doctorId;
    let studyId = JSON.parse(localStorage.getItem("studyId") || '{}');
    console.log(studyId);
    this.submitVisitModel.studyId = studyId;
  }
  buildForm() {
    const controlArray = this.thisIsMyForm.get('formArrayName') as FormArray;
    console.log("Data" + this.section1);
    Object.keys(this.section1).forEach((i) => {
      // console.log(this.section1[i]);
      if (this.status == "OPEN") {
        controlArray.push(
          this.formBuilder.group({
            answer: [''],
            questionId: this.section1[i].questionId,
            sectionId: this.section1[i].sectionId,
            userId: this.submitVisitModel.userId,
            patientId: this.submitVisitModel.patientId,
            visitId: this.submitVisitModel.visitId,
          //  doctorId: this.submitVisitModel.doctorId,
            studyId: this.submitVisitModel.studyId,
            remarks: "",
          })
        )
      } else {
        controlArray.push(
          this.formBuilder.group({
            answer: this.section1[i].answer,
            questionId: this.section1[i].questionId,
            sectionId: this.section1[i].sectionId,
            userId: this.submitVisitModel.userId,
            patientId: this.submitVisitModel.patientId,
            visitId: this.submitVisitModel.visitId,
          //  doctorId: this.submitVisitModel.doctorId,
            studyId: this.submitVisitModel.studyId,
            remarks: "",
          })
        )
      }

    })
    const controlArrays = this.thisIsMyForm.get('formArrayName1') as FormArray;

    Object.keys(this.section2).forEach((i) => {
      //console.log(this.section1[i]);
      if (this.status == "OPEN") {
        controlArrays.push(
          this.formBuilder.group({
            answer: [''],
            questionId: this.section2[i].questionId,
            sectionId: this.section2[i].sectionId,
            userId: this.submitVisitModel.userId,
            patientId: this.submitVisitModel.patientId,
            visitId: this.submitVisitModel.visitId,
           // doctorId: this.submitVisitModel.doctorId,
            studyId: this.submitVisitModel.studyId,
            remarks: "",
          })
        )
      } else {
        controlArrays.push(
          this.formBuilder.group({
            answer: this.section2[i].answer,
            questionId: this.section2[i].questionId,
            sectionId: this.section2[i].sectionId,
            userId: this.submitVisitModel.userId,
            patientId: this.submitVisitModel.patientId,
            visitId: this.submitVisitModel.visitId,
           // doctorId: this.submitVisitModel.doctorId,
            studyId: this.submitVisitModel.studyId,
            remarks: "",
          })
        )
      }

    })

    // console.log(controlArray.controls)

  }

  equal() {
    //  this.totalcrfarray=[];
    // Here I would like to be able to access the values of the 'forms'
    console.log(this.thisIsMyForm.value)
    this.array = this.thisIsMyForm.value;
    for (let datum of this.array.formArrayName) {
      this.crfarray.push(datum);// add data into array
    }
    this.array1 = this.thisIsMyForm.value;
    for (let datum of this.array1.formArrayName1) {
      this.crfarray1.push(datum);// add data into array
    }
    this.totalcrfarray = this.crfarray.concat(this.crfarray1);

    console.log(this.totalcrfarray)
    this.apiService.visitmethod(this.totalcrfarray)
      .subscribe(visitdata => {
        console.log(visitdata)
        this.route.navigate(['CRFView']);
      })
  }
  //input text function
  inputtext(e: any) {
    // console.log(e.target.value)
    this.intext = e.target.value;
  }
  Submit() {
    console.log(this.intext)
    let data3 = JSON.parse(localStorage.getItem("datass") || '{}');
    let studyId = JSON.parse(localStorage.getItem("studyId") || '{}');

    let Crfdata2 = JSON.parse(localStorage.getItem("Crfdata") || '{}');
    let doctorId = JSON.parse(localStorage.getItem("CrfdoctorId") || '{}');
    if(this.intext !==undefined){
   // this.approvePatient.doctorId = doctorId;
    this.approvePatient.doctorRemarks = this.intext;
    this.approvePatient.patientId = data3;
    this.approvePatient.studyId = studyId;
    this.approvePatient.visitId = Crfdata2;
    console.log( this.approvePatient)
  
    this.apiService.ApprovePatientmethod(this.approvePatient)
      .subscribe(Approvedata => {
        console.log(Approvedata)
        this.route.navigate(['CRFView']);
      })
    }else{
      alert("Please Fill Remarks")
    }

  }
}
