import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AdverseModel } from '../adversemodel';
import { ApiService } from '../api.service';
import { DrugStockModel } from '../drugstockmodel';
@Component({
  selector: 'app-medicationdetails',
  templateUrl: './medicationdetails.component.html',
  styleUrls: ['./medicationdetails.component.css']
})
export class MedicationdetailsComponent implements OnInit {
  patientId: any;
  adverseModel = new AdverseModel();
  drugStockModel: DrugStockModel;
  constructor(private route: Router, private apiService: ApiService) { }

  ngOnInit() {
    let data2 = JSON.parse(localStorage.getItem("datass") || '{}');
    this.adverseModel.patientId = data2
    let logiResoutputdata2 = JSON.parse(localStorage.getItem("datas") || '{}');
    this.adverseModel.userId = logiResoutputdata2.result.userId;
    this.apiService.drugstockmethod(this.adverseModel)
      .subscribe(medicationstockdata => {
        console.log(medicationstockdata)
        this.drugStockModel = medicationstockdata.result
        console.log(this.drugStockModel[0].productId)
      })
  }
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('datas');

    this.route.navigate(['login']);
  }
}
