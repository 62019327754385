export class ParticipantScreeningModel{
        
    "studyId":Number;
    "userId":Number;
    "patientId":Number;
    "testName":String;
    "testValue":String;
    "fileURL":String;
   "dayNumber":Number;
    "mobileNumber":Number;


       
//    "studyId":"",
//    "userId":"",
//    "patientId":"",
//    "testName":"Vitamin D",
//    "testValue":"",
//    "fileURL":"",
//   "dayNumber":"1"
}