import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PinComponent } from './pin/pin.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { StudyDocumentsComponent } from './study-documents/study-documents.component';
import { ParticipantComponent } from './participant/participant.component';
 import { PdfViewerModule } from 'ng2-pdf-viewer'; 
 import { NgbModule,NgbModal } from '@ng-bootstrap/ng-bootstrap';
 import { Ng2SearchPipeModule } from 'ng2-search-filter';
 import { FormsModule, ReactiveFormsModule } from '@angular/forms';
 import { MatIconModule } from "@angular/material/icon";
 import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
 import { MatTabsModule,MatButtonModule,MatToolbarModule} from '@angular/material';
 import { SignaturePadModule } from 'angular2-signaturepad';
import{HelloComponent} from './hello.component';
import { NewparticipantComponent } from './newparticipant/newparticipant.component';
import { CRFComponent } from './crf/crf.component';
import { CRFDetailsComponent } from './crfdetails/crfdetails.component';
import { HttpClientModule } from '@angular/common/http';
import { ApiService } from './api.service';
import{LoginResponseService} from'./loginresponeservice';
import { ParticipantdairyComponent } from './participantdairy/participantdairy.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { UpdatemedicineComponent } from './updatemedicine/updatemedicine.component';
import { SelectedlistComponent } from './selectedlist/selectedlist.component';
import { MedicationdetailsComponent } from './medicationdetails/medicationdetails.component';
import { LabreportsComponent } from './labreports/labreports.component';
import { NewlabreportComponent } from './newlabreport/newlabreport.component';
import { PatientscreeningComponent } from './patientscreening/patientscreening.component';
import { ApprovepatientComponent } from './approvepatient/approvepatient.component';
import { LoaderInterceptor } from './Loader.Interceptor';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import { DemoMaterialModule } from './material-module';
import { HeaderComponent } from './header/header.component';
import { ProfileComponent } from './profile/profile.component';
import { DatePipe } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    PinComponent,
    LoginComponent,
    DashboardComponent,
    StudyDocumentsComponent,
    ParticipantComponent,
    HelloComponent,
    NewparticipantComponent,
    CRFComponent,
    CRFDetailsComponent,
    ParticipantdairyComponent,
    UpdatemedicineComponent,
    SelectedlistComponent,
    MedicationdetailsComponent,
    LabreportsComponent,
    NewlabreportComponent,
    PatientscreeningComponent,
    ApprovepatientComponent,
    HeaderComponent,
    ProfileComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PdfViewerModule,
    NgbModule,
    Ng2SearchPipeModule,
    FormsModule,
    BrowserAnimationsModule, MatTabsModule, MatButtonModule, MatToolbarModule, MatIconModule,
    SignaturePadModule,
    HttpClientModule,
    FormsModule,ReactiveFormsModule,
    DemoMaterialModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  providers: [DatePipe,NgbModal,ApiService,LoginResponseService,DemoMaterialModule,{ provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
