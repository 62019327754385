<!DOCTYPE html>
<html>

<head>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css	" />
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
  <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.bundle.min.js"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
  <style>
  </style>
</head>

<body>
  <div class="row d-flex">
    <div class="col-lg-6">
      <div class="card1">
        <div class="row">
          <img [src]="loginImage" class="loginImage" alt="Landing">
        </div>
      </div>
    </div>
    <div class="col-lg-6 landing-right">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="text-center">
        </div>
        <div class="card2 card border-0 px-4 py-5">
          <div class="container">
            <div class="text-center" >
              <h5 class="font-weight-lighter" class='radiobuttonsheight'> Please select below option </h5>
            </div>
            <div style="padding-bottom: 30px;" class="row">
              <div class="custom-radio col-md-4">
                <input type="radio" class="custom-control-input" id="customRadio1" value="5"
                  (change)="onItemChange($event.target.value)" name="radiovalue" required>
                <label class="custom-control-label" for="customRadio1"><b>Investigator</b></label>
              </div>
              <div class="custom-radio col-md-4">
                <input type="radio" class="custom-control-input" id="customRadio2" value="14"
                  (change)="onItemChange($event.target.value)" name="radiovalue" required>
                <label class="custom-control-label" for="customRadio2"><b>Co-Investigator</b></label>
              </div>
              <div class="custom-radio col-md-4">
                <input type="radio" class="custom-control-input" id="customRadio3" value="13"
                  (change)="onItemChange($event.target.value)" name="radiovalue" required>
                <label class="custom-control-label" for="customRadio3"><b>Coordinator</b></label>
              </div>
            </div>
          </div>
          <div class="row px-3"> <label class="mb-1">
              <h6 class="mb-0 text-sm">User Name</h6>
            </label>
            <input class="mb-4" type="text" name="username" placeholder="Enter UserName" formControlName="username">
          </div>
          <div class="row px-3"> <label class="mb-1">
              <h6 class="mb-0 text-sm">Password</h6>
            </label>
            <input type="password" name="password" placeholder="Enter password" formControlName="password">
          </div>
          <br>
          <div class="row px-3 mb-4">
            <div class="custom-control custom-checkbox custom-control-inline">
              <input id="chk1" type="checkbox" name="chk" class="custom-control-input">
            </div> <a href="#" class="ml-auto mb-0 text-sm" [routerLink]="['/pin']">Forgot Password?</a>
          </div>
          <div class="text-center">
            <button class="btn btn-orange text-center">SUBMIT</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</body>
</html>